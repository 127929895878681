import { useEffect, useMemo } from 'react';
import { useLocalStorageState } from 'ahooks';
import { isEmpty } from 'lodash';
import storage from '@/shared/utils/storage';
import { ShoppingCartItem } from '@/components/cart/util/useShoppingCartHook';

export const useAuthorizedUserShoppingCartCache = (token: string) => {
  const authorizedUserShoppingCartCacheKey = useMemo(
    () => `${process.env.NEXT_PUBLIC_STORAGE_PREFIX}-cart-${token}`,
    [token],
  );

  // 授权用户购物车缓存数据
  const [authorizedUserShoppingCartCacheData, setAuthorizedUserShoppingCartCacheData] = useLocalStorageState<
    Array<ShoppingCartItem>
  >(authorizedUserShoppingCartCacheKey, {
    defaultValue: [],
    listenStorageChange: true,
  });

  useEffect(() => {
    if (!isEmpty(token)) {
      // 登录成功后，清除未登录时的购物车缓存数据，并将数据同步到登录后的购物车缓存数据中
      // setAuthorizedUserShoppingCartCacheData((v) => v ?? []);
      console.log(authorizedUserShoppingCartCacheKey);
      console.log(storage.get(`cart-${token}`)); // 经测试，useLocalStorageState会在刷新时，清除内存数据，本地存储也被清除，导致刷新后购物车数据丢失。必须重新从云端获取一次
      const cache = localStorage.getItem(`${process.env.NEXT_PUBLIC_STORAGE_PREFIX}-cart-${token}`);
      // console.log('------------', JSON.parse(cache));
      // setAuthorizedUserShoppingCartCacheData((v = []) => {
      //   console.log({ v });
      //   return v;
      // });
      if (cache) {
        setAuthorizedUserShoppingCartCacheData(JSON.parse(cache) || []);
      }

      // 主要是为了清除在没有登录前的 cart-undefined 及缓存数据
      storage.del('cart-undefined');
    }
  }, [authorizedUserShoppingCartCacheKey, setAuthorizedUserShoppingCartCacheData, token]);

  return { authorizedUserShoppingCartCacheData, setAuthorizedUserShoppingCartCacheData };
};
