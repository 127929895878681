import React from 'react';
import { useLocale } from 'next-intl';
import CheckStateIcon from './icon/CheckStateIcon';

interface PasswordValidationProps {
  validationState?: {
    lengthSufficient: boolean;
    withCapital: boolean;
    withNumber: boolean;
    withSpecial: boolean;
    // withoutLatin: boolean;
    // withoutSpace: boolean;
  };
  validateBeforeSubmit: boolean;
}

const PasswordValidation: React.FC<PasswordValidationProps> = ({ validationState, validateBeforeSubmit }) => {
  const currentLocale = useLocale();

  const requirements = [
    {
      key: 'lengthSufficient',
      label: {
        ru: 'Минимум 8 символов',
        en: 'Minimum 8 characters',
        kk: 'Кемінде 8 таңба',
      },
    },
    {
      key: 'withNumber',
      label: {
        ru: 'Цифра',
        en: 'Number',
        kk: 'Сан',
      },
    },
    {
      key: 'withCapital',
      label: {
        ru: 'Заглавная буква',
        en: 'Capital letter',
        kk: 'Бас әріп',
      },
    },
    {
      key: 'withSpecial',
      label: {
        ru: 'Символ (!@_)',
        en: 'Symbol (!@_)',
        kk: 'Таңба (!@_)',
      },
    },
  ];

  const stateColors = {
    '1': '#4DBAC0',
    '2': '#ff3141',
    '3': '#80878E',
  };

  return (
    <div className="grid grid-cols-2 gap-2">
      {requirements.map(({ key, label }) => {
        let state = '3';
        if (validationState?.[key as keyof typeof validationState]) {
          state = '1';
        } else {
          if (validateBeforeSubmit) {
            state = '2';
          } else {
            state = '3';
          }
        }

        return (
          <div key={key} className="flex items-center gap-2">
            <div className={`flex h-5 w-5 items-center justify-center rounded-full`}>
              <CheckStateIcon color={stateColors[state as '1' | '2' | '3']} />
            </div>
            <span
              className={`text-sm ${
                validationState?.[key as keyof typeof validationState]
                  ? 'text-[#4DBAC0]'
                  : validateBeforeSubmit
                    ? 'text-[#ff3141]'
                    : 'text-gray-500'
              }`}
            >
              {label[currentLocale as 'en' | 'ru' | 'kk']}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default PasswordValidation;
