import Image from 'next/image';

const HeaderBackH5 = ({ goBack }: any) => {
  return (
    <div className="relative w-full border-0 bg-transparent pb-4">
      <div className="absolute left-2 top-0 z-50 cursor-pointer" onClick={goBack}>
        <Image
          width={24}
          height={24}
          src={'/images/category/leftArrows.png'}
          className="mr-4 size-6"
          alt="leftArrows"
        />
      </div>
    </div>
  );
};

export default HeaderBackH5;
