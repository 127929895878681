// @ts-ignore
/* eslint-disable */
import request from '@/shared/utils/axios';

/** 批量修改购物车 这是一个覆盖购物车内容的接口，前端会批量传购物车数据到后端，后端需要删除该用户的原有购物车数据，然后将前端传入的购物车数据写入 PUT /product/web/shopping/cart */
export async function putProductWebShoppingCart(
  body: { productId: string; skuId?: number; quantity?: number }[],
  options?: { [key: string]: any },
) {
  return request<{ code: number; msg: string; data: Record<string, any> }>('/product/web/shopping/cart', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 添加商品到购车车 product-service POST /product/web/shopping/cart/add */
export async function postProductWebShoppingCartAdd(
  body: {
    productId: string;
    skuId: number;
    quantity: number;
  },
  options?: { [key: string]: any },
) {
  return request<{ code: number; msg: string; data: number }>('/product/web/shopping/cart/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 购物车商品列表 product-service
需要用户登录鉴权 GET /product/web/shopping/cart/list */
export async function getProductWebShoppingCartList(options?: { [key: string]: any }) {
  return request<{ code: number; msg: string; data: API.WebShoppingCartItemVo[] }>('/product/web/shopping/cart/list', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 更新购物车商品数量 PUT /product/web/shopping/cart/num/update */
export async function putProductWebShoppingCartNumUpdate(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.putProductWebShoppingCartNumUpdateParams,
  options?: { [key: string]: any },
) {
  return request<{ code: number; msg: string; data: number }>('/product/web/shopping/cart/num/update', {
    method: 'PUT',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 移除购物车商品 POST /product/web/shopping/cart/remove */
export async function postProductWebShoppingCartRemove(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postProductWebShoppingCartRemoveParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResult>('/product/web/shopping/cart/remove', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
