import Image from 'next/image';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocale, useTranslations } from 'next-intl';
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import SizeChart from '../SizeChart';
import { useToast } from '@/components/ui/use-toast';
import { useRequest } from 'ahooks';
import { LoaderIcon } from 'lucide-react';
import ShareBox from '../ShareBox';
import { useAuthProvider, useCartProvider, useCollectProvider, useDarkModeProvider } from '@/lib/context';
// import { useRouter } from 'next/navigation';
import { useRouter as userNextRouter } from 'next/router';
import { countAddSeparator, getUuid } from '@/shared/utils/tools';
import { getOrderPublicProductSales, putProductWebProductCollectProductId } from '@/lib/services/shangpinye';
import { deleteProductWebProductCollectCancelProductId } from '@/lib/services/shangpinshoucang';
import { Input } from '@/components/ui/input';
import copy from 'copy-to-clipboard';
import Link from 'next/link';
import ColorListBox from '../ColorListBox';
import EM from '@/shared/utils/EM';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, Zoom } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/pagination';

const BigImageSwiper = ({
  images,
  onClickToShowBigImage,
  canZoom = false,
}: {
  images: string[];
  onClickToShowBigImage?: () => void;
  canZoom?: boolean;
}) => {
  return (
    <Swiper
      modules={[Zoom, Pagination, Autoplay]}
      spaceBetween={50}
      slidesPerView={1}
      autoplay={
        canZoom
          ? false
          : {
              delay: 5000,
            }
      }
      zoom={canZoom}
      loop
      pagination={{ type: 'fraction' }}
      className={`${canZoom ? 'h-full w-full' : ''}`}
    >
      {(images || []).map((productImagesItem: any, index: number) => {
        return (
          <SwiperSlide key={index} className={`${canZoom ? 'h-full w-full' : ''}`}>
            {canZoom ? (
              <div className="swiper-zoom-container">
                <Image
                  src={productImagesItem || '/images/banner/defaultImage.png'}
                  width={1440}
                  height={240}
                  className="h-[106.67vw] w-full object-cover"
                  alt="productImage"
                  onClick={() => {
                    if (onClickToShowBigImage) {
                      onClickToShowBigImage();
                    }
                  }}
                />
              </div>
            ) : (
              <Image
                src={productImagesItem || '/images/banner/defaultImage.png'}
                width={1440}
                height={240}
                className="h-[106.67vw] w-full object-cover"
                alt="productImage"
                onClick={() => {
                  if (onClickToShowBigImage) {
                    onClickToShowBigImage();
                  }
                }}
              />
            )}
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

interface AddShoppingCartParams {
  subject: string;
  subjectEN?: string;
  subjectKZ?: string;
  id: string;
  favorited: boolean;
  price: number;
  skuList: {
    id?: number;
    image?: string;
    skuAttributes?: {
      attributeId?: string;
      attributeName?: string;
      value?: string;
      attributeNameCN?: string;
      valueCN?: string;
    }[];
    price?: number;
  }[];
  productImages: string[];
  productBuySpecification: {
    attributeId?: string;
    attributeName?: string;
    attributeValue?: string;
    attributeNameCN?: string;
    attributeValueCN?: string;
    attributeNameEN?: string;
    attributeNameKZ?: string;
    attributeValueEN?: string;
    attributeValueKZ?: string;
  }[];
  score: number;
  /** 评价次数 */
  reviewsTotal: number;
  sales: number;
  category?: string;
  categoryName?: string;
  skuAttributes?: {
    attributeId?: string;
    attributeName?: string;
    value?: string;
    attributeNameCN?: string;
    valueCN?: string;
  }[];
}

const AddShoppingCart = ({
  productDetail,
  closeDialog,
  showMoreButton = false,
  onClickMoreButton,
  onOpenShareBox,
  onFavoritedChange,
  showH5BuyButton,
  buttonWidth,
  showSwipper = false,
  showReviewList,
}: {
  productDetail: AddShoppingCartParams;
  closeDialog?: () => void;
  onClickMoreButton?: () => void;
  onOpenShareBox?: (value: boolean) => void;
  onFavoritedChange?: (value: boolean) => void;
  showMoreButton?: boolean;
  showH5BuyButton?: boolean;
  buttonWidth?: number;
  showSwipper?: boolean;
  showReviewList?: () => void;
}) => {
  console.log(showH5BuyButton);
  const { handleAddProductToShoppingCart } = useCartProvider();
  const { handleRefreshColelectTotal } = useCollectProvider();
  const [price, setPrice] = useState<number>(0);
  const { isLogin } = useAuthProvider();
  const [showCopySuccess, setShowCopySuccess] = useState<boolean>(false); //复制成功
  const currentLocale = useLocale();
  const [isLoginNew, setIsLoginNew] = useState<boolean>(false);
  const [productIsRemoved, setProductRemoved] = useState<boolean>(false);

  useEffect(() => {
    setIsLoginNew(!!isLogin);
  }, [isLogin]);

  const t = useTranslations('Product');
  const t1 = useTranslations();
  const { pathname } = userNextRouter();
  const { toast } = useToast();
  const [renderImages, setRenderImages] = useState<string[]>([]); //左侧，图片视频展示数组
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0); // 选择中的图片或视频的下标
  const [openShareBox, setOpenShareBox] = useState<boolean>(false);
  const [quantity, setQuantity] = useState<number>(1); //加入购物车数量
  const [showImageList, setShowImageList] = useState<boolean>(false);
  const [centerImage, setCenterImage] = useState<string>(); // 中间大图
  const [showBigImageSwipperInH5, setShowBigImageSwipperInH5] = useState<boolean>(false); // 在h5页面查看大图

  const [selectedAttributeList, setSelectedAttributeList] = useState<
    {
      attributeNameCN?: string;
      attributeValueCN?: string;
    }[]
  >([]);
  const [favorited, setFavorited] = useState<boolean>(); //是否收藏

  useEffect(() => {
    setFavorited(productDetail?.favorited);
  }, [productDetail]);

  // 遍历所有指标，生成每个指标可选sku列表
  const valueFilterMap = useMemo(() => {
    const map: Record<string, string[]> = {};

    productDetail?.productBuySpecification.forEach((specificationIetm) => {
      // 排除当前指标已选择的属性，生成新的已选择属性列表
      const newselectedAttributeList =
        selectedAttributeList.filter((selectedAttributeItem) => {
          return selectedAttributeItem.attributeNameCN !== specificationIetm.attributeNameCN;
        }) || [];

      let skuList = [];
      // 新的已选择属性列表 如果为0，则可选sku列表为所有sku
      if (newselectedAttributeList.length === 0) {
        skuList = productDetail?.skuList;
      } else {
        skuList =
          productDetail?.skuList?.filter((skuItem) => {
            return newselectedAttributeList.every((attributeItem) => {
              return skuItem?.skuAttributes?.some((skuItemAttribute) => {
                return (
                  skuItemAttribute.valueCN === attributeItem.attributeValueCN &&
                  skuItemAttribute.attributeNameCN === attributeItem.attributeNameCN
                );
              });
            });
          }) || [];
      }

      map[specificationIetm.attributeNameCN!] =
        skuList.map((sku) => {
          return (
            sku.skuAttributes?.find(
              (skuAttribute) => skuAttribute.attributeNameCN === specificationIetm.attributeNameCN,
            )?.valueCN || ''
          );
        }) || [];
      map[specificationIetm.attributeNameCN!] = Array.from(new Set(map[specificationIetm.attributeNameCN!]));
    });

    // console.log('valueFilterMap', map);
    return map;
  }, [productDetail?.productBuySpecification, productDetail?.skuList, selectedAttributeList]);

  // 设置中间大图展示的图片
  useEffect(() => {
    setRenderImages(productDetail?.productImages || []);
    if (productDetail?.productImages && productDetail?.productImages?.length) {
      setCenterImage(productDetail?.productImages[0]);
    }
  }, [productDetail?.productImages]);

  // 选择属性 -- 保存中文属性，俄文翻译可以错误，但是中文不会错
  const clickAttribute = (
    item: {
      attributeId?: string;
      attributeName?: string;
      attributeValue?: string;
      attributeNameCN?: string;
      attributeValueCN?: string;
    },
    index: number,
  ) => {
    const temp = {
      attributeNameCN: item.attributeNameCN,
      attributeValueCN: item.attributeValueCN?.split(',')[index],
    };
    const tepmData = [...selectedAttributeList];
    const tepmIndex = (tepmData || []).findIndex((x) => x.attributeNameCN === item.attributeNameCN);
    if (tepmIndex === -1) {
      tepmData.push(temp);
    } else {
      if (temp.attributeValueCN === tepmData[tepmIndex].attributeValueCN) {
        tepmData.splice(tepmIndex, 1);
      } else {
        tepmData[tepmIndex] = temp;
      }
    }
    // 选中的sku对应的价格
    const newPrice = findPriceByAttributes(productDetail?.skuList || [], tepmData);
    if (newPrice) {
      setPrice(newPrice);
    }
    setSelectedAttributeList(tepmData);
  };

  //  点击加入购物车按钮
  const addShoppingCart = async () => {
    // 判断是否每个属性都选择了的
    // 没有选中的属性名
    const notSelectedAttributeName = (productDetail?.productBuySpecification || []).find(
      (x) => !(selectedAttributeList || []).find((y) => y.attributeNameCN === x.attributeNameCN),
    )?.attributeName;

    if (notSelectedAttributeName) {
      toast({
        title: t('pleaseChoose') + ' ' + notSelectedAttributeName,
      });
      return;
    }

    // 每个属性都选择了，获取skuId
    // 查找与 selectedAttribute 匹配的 SKU
    const matchingSku = (productDetail?.skuList || []).find((sku) =>
      selectedAttributeList.every((selectedAttr) =>
        sku.skuAttributes?.some((attr) => {
          return (
            attr.attributeNameCN === selectedAttr.attributeNameCN && attr.valueCN === selectedAttr.attributeValueCN
          );
        }),
      ),
    );

    // 加入购物车
    if (matchingSku?.id) {
      console.log({ matchingSku });
      setPrice(matchingSku.price || 0);
      const res = await handleAddProductToShoppingCart({
        ...productDetail,
        productImage: productDetail.productImages[0],
        skuId: matchingSku?.id,
        quantity: quantity ? (quantity > 4 ? 4 : quantity < 1 ? 1 : quantity) : 1,
        selected: true,
        productId: productDetail.id,
        skuAttributes: matchingSku.skuAttributes,
        id: getUuid(), // 前端生成临时列表id，保留方便调试
        price: matchingSku.price, // 要用这个价格
      } as any);
      console.log(res);
      if (res === 'REMOVED') {
        setProductRemoved(true);
        return;
      }

      closeDialog?.();
      if (!pathname.includes('/cart')) {
        // router.push(`/cart`);
      }
    }
  };

  //  收藏商品
  const { run: collectProduct, loading: collectProductLoading } = useRequest(
    () =>
      putProductWebProductCollectProductId({
        productId: productDetail?.id,
      }),
    {
      manual: true,
      onSuccess: (res) => {
        if (res?.code === 0) {
          if (handleRefreshColelectTotal) {
            handleRefreshColelectTotal();
          }
        }
        setFavorited(true);
        if (onFavoritedChange) {
          onFavoritedChange(true);
        }
        // toast({
        //   description: (
        //     <div className="flex flex-row items-center justify-start font-semibold">
        //       <Image
        //         src={'/images/product/messages.png'}
        //         width={24}
        //         height={24}
        //         className="mr-2 size-6"
        //         alt="productImages"
        //       />
        //       {t('thisProductHasBeenAddedToTheFavorites')}
        //     </div>
        //   ),
        // });
      },
    },
  );
  //  取消收藏商品
  const { run: cancelCollection, loading: cancelCollectionLoading } = useRequest(
    () =>
      deleteProductWebProductCollectCancelProductId({
        productId: productDetail?.id,
      }),
    {
      manual: true,
      onSuccess: (res) => {
        if (res?.code === 0) {
          if (handleRefreshColelectTotal) {
            handleRefreshColelectTotal();
          }
        }
        setFavorited(false);
        if (onFavoritedChange) {
          onFavoritedChange(false);
        }
        // toast({
        //   description: (
        //     <div className="flex flex-row items-center justify-start font-semibold">
        //       {t('thisProductHasBeenRemovedFromTheFavorites')}
        //     </div>
        //   ),
        // });
      },
    },
  );

  // 默认选中第一个颜色和第一个尺码
  useEffect(() => {
    if (productDetail?.productBuySpecification && !!productDetail?.productBuySpecification.length) {
      const newSelectedAttributeList = [...selectedAttributeList];
      (productDetail?.productBuySpecification || []).forEach((productBuySpecificationItem) => {
        newSelectedAttributeList.push({
          attributeNameCN: productBuySpecificationItem?.attributeNameCN,
          attributeValueCN: productBuySpecificationItem?.attributeValueCN?.split(',')[0],
        });
      });

      // 去重选中的属性
      const uniqueData = Array.from(new Set(newSelectedAttributeList.map((item) => JSON.stringify(item)))).map((item) =>
        JSON.parse(item),
      );

      // 选中的sku对应的价格
      const newPrice = findPriceByAttributes(productDetail?.skuList || [], uniqueData);
      setPrice(newPrice || 0);

      setSelectedAttributeList(newSelectedAttributeList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetail?.productBuySpecification]);

  const { darkMode } = useDarkModeProvider();

  const findPriceByAttributes = (skuList: any[], attributes: any[]) => {
    // 遍历 skuList
    for (const sku of skuList) {
      let match = true;

      // 遍历第二个数组（attributes）中的每个属性
      for (const attribute of attributes) {
        // 查找sku中对应的属性
        const attributeInSku = sku.skuAttributes.find(
          (attr: { attributeNameCN: any }) => attr.attributeNameCN === attribute.attributeNameCN,
        );

        // 如果sku中找不到这个属性，或者属性值不匹配，标记为不匹配
        if (!attributeInSku || attributeInSku.valueCN !== attribute.attributeValueCN) {
          match = false;
          break;
        }
      }

      // 如果所有属性都匹配，返回该商品的价格
      if (match) {
        return sku.price;
      }
    }

    // 如果没有找到匹配的商品，返回null
    return null;
  };

  // 获取销售数量 getOrderPublicProductSales
  const { data: orderWebProductSales } = useRequest(
    () =>
      getOrderPublicProductSales({
        productId: productDetail?.id,
      }),
    {
      manual: !productDetail?.id,
      refreshDeps: [productDetail?.id],
      cacheKey: 'getOrderPublicProductSales',
    },
  );

  const addShoppingCartNumberInputRef = useRef(null);
  useEffect(() => {
    if (addShoppingCartNumberInputRef.current) {
      // 取消选中效果
      const input: any = addShoppingCartNumberInputRef.current;
      input.blur();
      input.focus();
    }
  }, []);

  const getReviewsSuffix = (reviewsTotal: number) => {
    switch (reviewsTotal) {
      case 0:
        return t1('Goods.zeroStar');
      case 1:
        return t1('Goods.oneStar');
      case 2:
        return t1('Goods.twoStar');
      case 3:
        return t1('Goods.threeStar');
      case 4:
        return t1('Goods.fourStar');
      default:
        return t1('Goods.fiveStar');
    }
  };

  useEffect(() => {
    // 如果showCopySuccess状态变化，设置新的定时器
    if (showCopySuccess) {
      const timeoutId = setTimeout(() => {
        setShowCopySuccess(false);
      }, 2000);

      // 清除定时器，防止内存泄漏
      return () => clearTimeout(timeoutId);
    }
  }, [showCopySuccess]);

  return (
    <div className="flex w-full flex-row items-start justify-start max-md:h-full max-md:flex-col max-md:overflow-y-auto md:min-h-[500px]">
      {/* 小图列 */}
      <div className="mr-3 h-full w-[120px] max-md:hidden">
        {(renderImages || []).map((item, index) => {
          return (
            <div
              key={index}
              className={`mb-[6px] h-[120px] w-[120px] cursor-pointer overflow-hidden rounded-md ${
                selectedImageIndex === index
                  ? 'border-4 border-solid border-[#4DBAC0]'
                  : 'border border-solid border-[#EBECEE]'
              }`}
              onClick={() => {
                setSelectedImageIndex(index);
                setCenterImage(renderImages[index]);
              }}
            >
              {/* TODO 可播放视频 */}
              <Image
                src={item}
                width={120}
                height={120}
                className="h-[120px] w-[120px] object-cover"
                alt="productImages"
              />
            </div>
          );
        })}
        {!!renderImages && renderImages.length > 1 ? (
          <div className="flex w-full flex-row items-center justify-between">
            <div
              style={{ backgroundColor: 'rgba(50, 129, 129, 0.06)' }}
              className={`flex h-6 w-[57px] select-none flex-row items-center justify-center rounded-md ${
                selectedImageIndex <= 0 ? 'cursor-not-allowed' : 'cursor-pointer'
              }`}
              onClick={() => {
                if (selectedImageIndex <= 0) {
                  return;
                }
                setSelectedImageIndex(selectedImageIndex - 1);

                setCenterImage(renderImages[selectedImageIndex - 1]);
              }}
            >
              <Image src={'/images/product/up.png'} className="h-auto w-4" alt="upImage" width={16} height={16} />
            </div>
            <div
              style={{ backgroundColor: 'rgba(50, 129, 129, 0.06)' }}
              className={`flex h-6 w-[57px] select-none flex-row items-center justify-center rounded-md ${
                selectedImageIndex >= 0 && selectedImageIndex >= renderImages?.length - 1
                  ? 'cursor-not-allowed'
                  : 'cursor-pointer'
              }`}
              onClick={() => {
                if (selectedImageIndex >= 0 && selectedImageIndex >= renderImages?.length - 1) {
                  return;
                }
                setSelectedImageIndex(selectedImageIndex + 1);

                setCenterImage(renderImages[selectedImageIndex + 1]);
              }}
            >
              <Image src={'/images/product/down.png'} className="h-auto w-4" alt="downImage" width={16} height={16} />
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      {/* 中间大图 */}
      <div className="ml-3 flex-1 max-md:hidden">
        {centerImage ? (
          <Image
            src={centerImage}
            width={456}
            height={120}
            className="h-auto w-full cursor-pointer"
            alt="productImages"
            onClick={() => {
              setShowImageList(true);
              if (onOpenShareBox) {
                onOpenShareBox(true);
              }
            }}
          />
        ) : (
          ''
        )}
      </div>
      <div
        className={`box-border ${buttonWidth ? 'w-[600px]' : 'w-1/2'} pl-6 max-md:box-border max-md:w-full max-md:pl-0`}
      >
        {showSwipper && (
          <div className="relative md:hidden">
            <BigImageSwiper
              onClickToShowBigImage={() => setShowBigImageSwipperInH5(true)}
              images={productDetail?.productImages || []}
            />
            <div className="absolute right-0 top-0 z-50 flex w-full flex-row items-center justify-end px-4 pt-2">
              {/* 分享按钮 */}
              <Image
                src={'/images/product/share_black.png'}
                width={24}
                height={24}
                className={`size-6 cursor-pointer`}
                alt="collectImage"
                onClick={() => {
                  setOpenShareBox(true);
                  if (onOpenShareBox) {
                    onOpenShareBox(true);
                  }
                }}
              />
              {/* 收藏按钮 */}
              <Image
                src={favorited ? '/images/goods/love.png' : '/images/product/like_black.png'}
                width={24}
                height={24}
                className={`ml-6 size-6 cursor-pointer`}
                alt="collectImage"
                onClick={() => {
                  if (!isLoginNew) {
                    EM.emit('toLogin');
                    return;
                  }
                  if (!favorited) {
                    collectProduct();
                  } else {
                    //   取消收藏
                    cancelCollection();
                  }
                }}
              />
            </div>
          </div>
        )}

        <div className={`w-full max-md:mb-3 max-md:p-3 ${darkMode ? 'max-md:bg-black' : 'max-md:bg-white'}`}>
          {/* 商品名 */}
          <h1
            className={`mb-2 w-full text-lg font-semibold leading-6 max-md:mb-2 max-md:text-base max-md:font-medium ${darkMode ? 'text-white' : 'text-black'}`}
          >
            {currentLocale === 'en'
              ? productDetail?.subjectEN
              : currentLocale === 'ru'
                ? productDetail?.subject
                : productDetail?.subjectKZ}
          </h1>
          {/* 评分 销量 id */}
          <div className="mb-2 flex w-full flex-row items-center justify-start max-md:hidden">
            <div
              className="flex cursor-pointer flex-row items-center justify-start"
              onClick={() => {
                if (showReviewList) {
                  showReviewList();
                }
              }}
            >
              <Image src={'/images/goods/like.png'} width={24} height={24} className="mr-1 size-6" alt="likeImage" />
              <div
                className={`mr-6 flex flex-row items-center justify-start text-base font-medium ${darkMode ? 'text-white' : 'text-black'}`}
              >
                {productDetail?.score || 0} ({countAddSeparator(productDetail?.reviewsTotal || 0)}{' '}
                {getReviewsSuffix(productDetail?.reviewsTotal || 0)})
              </div>
            </div>
            <div className={`mr-6 text-base font-medium ${darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'}`}>
              {countAddSeparator(orderWebProductSales?.data || 0)} {t('sale')}
            </div>
            <Image
              src={'/images/product/copy.png'}
              width={20}
              height={20}
              className="mr-1 size-5 cursor-pointer"
              alt="copyImage"
              onClick={() => {
                try {
                  copy(productDetail?.id);
                  setShowCopySuccess(true);
                  // toast({
                  //   description: (
                  //     <div className="flex flex-row items-center justify-start font-semibold">
                  //       <Image
                  //         src={'/images/product/messages.png'}
                  //         width={24}
                  //         height={24}
                  //         className="mr-2 size-6"
                  //         alt="productImages"
                  //       />{' '}
                  //       {t('copyduccessful')}
                  //     </div>
                  //   ),
                  // });
                } catch (err) {
                  console.error('Failed to copy text: ', err);
                }
              }}
            />
            <div className={`text-base font-medium ${darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'}`}>
              {t('itemNumber')}: {productDetail?.id}
            </div>
          </div>
          {/* 评分 销量 id */}
          <div className="mb-2 w-full md:hidden">
            <div className="mb-3 flex w-full flex-row items-center justify-start">
              <div
                className="flex w-1/2 flex-row items-center justify-start"
                onClick={() => {
                  if (showReviewList) {
                    showReviewList();
                  }
                }}
              >
                <Image src={'/images/goods/like.png'} width={16} height={16} className="mr-1 size-4" alt="likeImage" />
                <div
                  className={`flex flex-row items-center justify-start text-xs font-medium ${darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'}`}
                >
                  {productDetail?.score || 0} ({countAddSeparator(productDetail?.reviewsTotal || 0)} {t('reviews')})
                </div>
              </div>
              <div className={`w-1/2 text-right text-xs font-medium ${darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'}`}>
                {countAddSeparator(productDetail?.sales || 0)} {t('sale')}
              </div>
            </div>
            <div className="flex w-full flex-row items-center justify-start">
              <Image
                src={'/images/product/copy.png'}
                width={20}
                height={20}
                className="mr-1 size-5 cursor-pointer"
                alt="copyImage"
                onClick={() => {
                  try {
                    copy(productDetail?.id);

                    setShowCopySuccess(true);
                    // toast({
                    //   description: (
                    //     <div className="flex flex-row items-center justify-start font-semibold">
                    //       <Image
                    //         src={'/images/product/messages.png'}
                    //         width={16}
                    //         height={16}
                    //         className="mr-1 size-4"
                    //         alt="productImages"
                    //       />{' '}
                    //       {t('copyduccessful')}
                    //     </div>
                    //   ),
                    // });
                  } catch (err) {
                    console.error('Failed to copy text: ', err);
                  }
                }}
              />
              <div className={`text-xs font-medium ${darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'}`}>
                {t('itemNumber')}: {productDetail?.id}
              </div>
            </div>
          </div>
          {/* 价格 */}
          <div
            className={`${price >= 2400 ? 'mb-2' : 'mb-6'} text-lg font-semibold leading-6 max-md:mb-0 ${darkMode ? 'text-white' : 'text-black'}`}
          >
            {countAddSeparator(price || 0)} ₸
          </div>
          {/* 分期，每期金额 */}
          {price >= 2400 ? (
            <div className={`mb-6 flex flex-row items-center justify-start`}>
              <div
                className={`mr-2 flex h-7 w-[76px] flex-row items-center justify-center rounded-full bg-primary text-sm font-medium text-white`}
              >
                0•0•24
              </div>
              <div
                className={`flex flex-row items-center justify-start text-sm font-medium ${darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'}`}
              >
                {countAddSeparator(Math.ceil((price || 0) / 24 || 0))} ₸ X 24 {t('months')}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className={`w-full max-md:mb-3 max-md:p-3 ${darkMode ? 'max-md:bg-black' : 'max-md:bg-white'}`}>
          {/* // 分类型- 颜色 、 尺码 -- 后续添加类型再写 */}
          {/* 颜色 */
          /* 颜色始终排在最前面 */}
          {(productDetail?.productBuySpecification || [])
            .filter((x) => x.attributeName === 'Цвет' || x.attributeName === 'цвет' || x.attributeNameCN === '颜色')
            .map((specificationItem) => {
              const canChooseValueList = valueFilterMap[specificationItem.attributeNameCN!] || [];

              return (
                <div key={specificationItem.attributeId} className="mb-4 w-full max-md:mb-3">
                  <div className={`mb-3 text-base font-semibold ${darkMode ? 'text-white' : 'text-black'}`}>
                    {/* {t("color")} */}
                    {currentLocale === 'en'
                      ? specificationItem?.attributeNameEN || specificationItem?.attributeName
                      : currentLocale === 'ru'
                        ? specificationItem?.attributeName === 'цвет'
                          ? 'Цвет'
                          : specificationItem?.attributeName
                        : specificationItem.attributeNameKZ || specificationItem?.attributeName}
                  </div>
                  <div className="flex w-full flex-row flex-wrap items-start justify-start">
                    {(currentLocale === 'en'
                      ? specificationItem && specificationItem?.attributeValueEN
                        ? specificationItem?.attributeValueEN?.split(',') || []
                        : specificationItem?.attributeValue?.split(',') || []
                      : currentLocale === 'ru'
                        ? specificationItem && specificationItem?.attributeValue
                          ? specificationItem?.attributeValue?.split(',') || []
                          : []
                        : specificationItem && specificationItem?.attributeValueKZ
                          ? specificationItem?.attributeValueKZ?.split(',') || []
                          : specificationItem?.attributeValue?.split(',') || []
                    ).map((item1: string, index1: number) => {
                      const attributeNameCN = specificationItem.attributeValueCN?.split(',')[index1] || '';
                      const canChoose = canChooseValueList.includes(attributeNameCN);

                      const colorList = item1.split('_')[1]?.split('/') || [];

                      const colorCount = colorList.length;
                      const colorStops = colorList
                        .map((color, index) => {
                          const stopPercentage = (index / (colorCount - 1)) * 100;
                          return `${color} ${stopPercentage}%`;
                        })
                        .join(', ');

                      // 选判断是否有图片，有图片优先展示图片
                      const image = (productDetail?.skuList || []).find((skuItem) =>
                        (skuItem?.skuAttributes || []).find(
                          (skuAttributesItem) => skuAttributesItem?.valueCN === attributeNameCN,
                        ),
                      )?.image;

                      // 特殊处理，如果没有颜色，则按普通属性展示
                      return colorCount >= 1 ? (
                        <div key={index1} className="mb-2 mr-2 flex w-[60px] flex-col items-start justify-start">
                          <div
                            style={{
                              background: colorCount === 1 ? colorList[0] : `linear-gradient(to bottom, ${colorStops})`,
                              border: '1px solid #eeeeee',
                            }}
                            className={`relative mb-1 size-[60px] ${
                              canChoose ? 'cursor-pointer' : 'cursor-not-allowed'
                            } rounded-[4px]`}
                            onClick={() => {
                              if (canChoose) {
                                clickAttribute(specificationItem, index1);
                                if (image) {
                                  setCenterImage(image);
                                  setSelectedImageIndex(-1);
                                }
                              }
                            }}
                          >
                            {/* 修改为如果sku图片和主图一样，还是要展示图片，只有没有图片时，才展示色块  */}
                            {image ? (
                              <div className="absolute left-[50%] top-[50%] size-[60px] translate-x-[-50%] translate-y-[-50%] overflow-hidden rounded-[4px] border border-solid border-[#eeeeee]">
                                <Image
                                  src={image}
                                  width={60}
                                  height={60}
                                  objectFit="cover"
                                  className="h-full w-full"
                                  alt="image"
                                />
                              </div>
                            ) : (
                              ''
                            )}
                            {(selectedAttributeList || []).find(
                              (x) => x.attributeValueCN === specificationItem.attributeValueCN?.split(',')[index1],
                            ) ? (
                              <>
                                <div className="absolute left-[50%] top-[50%] h-full w-full translate-x-[-50%] translate-y-[-50%] rounded-[4px] border-2 border-solid border-[#4DBAC0]"></div>
                                <Image
                                  src={'/images/product/selected.png'}
                                  width={20}
                                  height={20}
                                  className="absolute bottom-[-7px] right-[-7px] size-5"
                                  alt="selectedImage"
                                />
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      ) : (
                        <div
                          key={index1}
                          className={`mb-2 mr-2 box-border flex h-8 min-w-12 ${
                            canChoose ? 'cursor-pointer' : 'cursor-not-allowed'
                          } flex-row items-center justify-center rounded-[8px] border border-solid px-2 text-xs font-medium ${
                            canChoose
                              ? (selectedAttributeList || []).find(
                                  (x) => x.attributeValueCN === specificationItem.attributeValueCN?.split(',')[index1],
                                )
                                ? `border-[#4DBAC0] bg-[#4DBAC0] ${darkMode ? 'text-white' : 'text-black'}`
                                : `border-[#4dbac03d] ${darkMode ? 'text-white' : 'text-black'} hover:border-[#4DBAC0]`
                              : 'border-[#eeeeee] bg-[#eeeeee] text-[#e4e6e8]'
                          }`}
                          onClick={() => {
                            if (canChoose) {
                              clickAttribute(specificationItem, index1);
                            }
                          }}
                        >
                          {item1}
                        </div>
                      );
                    })}
                  </div>
                  <div className={`text-xs font-medium ${darkMode ? 'text-white' : 'text-black'}`}>
                    {
                      (currentLocale === 'en'
                        ? specificationItem && specificationItem?.attributeValueEN
                          ? specificationItem?.attributeValueEN?.split(',') || []
                          : specificationItem?.attributeValue?.split(',') || []
                        : currentLocale === 'ru'
                          ? specificationItem && specificationItem?.attributeValue
                            ? specificationItem?.attributeValue?.split(',') || []
                            : []
                          : specificationItem && specificationItem?.attributeValueKZ
                            ? specificationItem?.attributeValueKZ?.split(',') || []
                            : specificationItem?.attributeValue?.split(',') || [])[
                        (specificationItem.attributeValueCN?.split(',') || []).findIndex(
                          (attributeValueCNItem) =>
                            attributeValueCNItem ===
                            (selectedAttributeList || []).find((x) => x.attributeNameCN === '颜色')?.attributeValueCN,
                        )
                      ]?.split('_')[0]
                    }
                  </div>
                </div>
              );
            })}
          {/* 其他 */}
          {(productDetail?.productBuySpecification || [])
            .filter((x) => !(x.attributeName === 'Цвет' || x.attributeName === 'цвет' || x.attributeNameCN === '颜色'))
            .map((specificationItem) => {
              // 尺码
              const canChooseValueList = valueFilterMap[specificationItem.attributeNameCN!] || [];

              return (
                <div key={specificationItem.attributeId} className="mb-4 w-full max-md:mb-3 max-md:last:mb-0">
                  <div className="mb-3 flex flex-row items-center justify-start">
                    <div className={`mr-6 text-base font-semibold ${darkMode ? 'text-white' : 'text-black'}`}>
                      {/* {t("size")} */}
                      {currentLocale === 'en'
                        ? specificationItem?.attributeNameEN || specificationItem?.attributeName
                        : currentLocale === 'ru'
                          ? specificationItem?.attributeName === 'размер'
                            ? 'Размер'
                            : specificationItem?.attributeName
                          : specificationItem.attributeNameKZ || specificationItem?.attributeName}
                    </div>
                    {specificationItem.attributeName === 'Размер' ||
                    specificationItem.attributeName === 'размер' ||
                    specificationItem.attributeNameCN === '尺码' ? (
                      <Dialog
                        onOpenChange={(value) => {
                          if (onOpenShareBox) {
                            onOpenShareBox(value);
                          }
                        }}
                      >
                        <DialogTrigger asChild>
                          <div className="item flex flex-row justify-start">
                            <Image
                              src={'/images/product/tip.png'}
                              width={20}
                              height={20}
                              className="mr-2 size-5"
                              alt="tipImage"
                            />
                            <div className="mt-[3px] cursor-pointer text-xs font-medium text-[#1890FF]">
                              {t('information')}
                            </div>
                          </div>
                        </DialogTrigger>
                        <DialogTitle></DialogTitle>
                        <DialogContent className="max-md:max-w-screen w-[708px] max-w-[708px] rounded-3xl border-none p-0 max-md:bottom-0 max-md:top-auto max-md:h-[70vh] max-md:w-screen max-md:translate-y-0 max-md:overflow-y-auto">
                          <SizeChart />
                        </DialogContent>
                      </Dialog>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="flex w-full flex-row flex-wrap items-start justify-start">
                    {(currentLocale === 'en'
                      ? specificationItem && specificationItem?.attributeValueEN
                        ? specificationItem?.attributeValueEN?.split(',') || []
                        : specificationItem?.attributeValue?.split(',') || []
                      : currentLocale === 'ru'
                        ? specificationItem && specificationItem?.attributeValue
                          ? specificationItem?.attributeValue?.split(',') || []
                          : []
                        : specificationItem && specificationItem?.attributeValueKZ
                          ? specificationItem?.attributeValueKZ?.split(',') || []
                          : specificationItem?.attributeValue?.split(',') || []
                    ).map((item1, index) => {
                      const attributeNameCN = specificationItem.attributeValueCN?.split(',')[index] || '';
                      const canChoose = canChooseValueList.includes(attributeNameCN);

                      return (
                        <div
                          key={index}
                          className={`mb-2 mr-2 box-border flex h-8 min-w-12 ${
                            canChoose ? 'cursor-pointer' : 'cursor-not-allowed'
                          } flex-row items-center justify-center rounded-[8px] border border-solid px-2 text-xs font-medium ${
                            canChoose
                              ? (selectedAttributeList || []).find(
                                  (x) => x.attributeValueCN === specificationItem.attributeValueCN?.split(',')[index],
                                )
                                ? `border-[#4DBAC0] bg-[#4DBAC0] ${darkMode ? 'text-white' : 'text-black'}`
                                : `border-[#4dbac03d] ${darkMode ? 'text-white' : 'text-black'} hover:border-[#4DBAC0]`
                              : 'border-[#eeeeee] bg-[#eeeeee] text-[#e4e6e8]'
                          }`}
                          onClick={() => {
                            if (canChoose) {
                              clickAttribute(specificationItem, index);
                            }
                          }}
                        >
                          {item1}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>
        {/* 数量 加入购物车 收藏按钮 分享按钮 */}
        <div
          className={`${darkMode ? 'max-md:bg-[#141414]' : 'max-md:bg-white'} flex w-full flex-row items-center justify-start max-md:bottom-0 max-md:right-0 max-md:z-50 max-md:h-[88px] max-md:w-full max-md:flex-col max-md:p-3 max-md:pt-0`}
        >
          <div className="mb-[10px] flex w-full flex-row items-center justify-between md:hidden">
            <div className={`text-base font-medium ${darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'}`}>
              {t('total')}
            </div>
            <div className={`text-base font-medium ${darkMode ? 'text-white' : 'text-black'}`}>
              {countAddSeparator((price || 0) * (quantity || 0))} ₸
            </div>
          </div>
          <div className="flex flex-row items-center justify-start max-md:w-full max-md:justify-between">
            {/* 数量 */}
            <div
              className={`max-md:round-[8px] mr-3 box-border flex h-12 ${buttonWidth ? 'w-[222px]' : 'w-[228px]'} flex-row items-center justify-between rounded-xl border border-solid border-[#4DBAC0] bg-[#4dbac01f] px-3 max-md:mr-0 max-md:h-11 max-md:w-[43vw]`}
            >
              <Image
                src={'/images/product/decrease.png'}
                width={20}
                height={20}
                className={`size-5 select-none ${quantity <= 1 ? 'cursor-not-allowed opacity-[24%]' : 'cursor-pointer'}`}
                alt="decreaseImage"
                onClick={() => {
                  if (quantity <= 1) {
                    return;
                  }
                  setQuantity(quantity - 1);
                }}
              />
              <div className="flex w-[132px] flex-row items-center justify-center text-center text-base font-medium text-[#4DBAC0]">
                <Input
                  type="number"
                  min={0}
                  // max={4}
                  autoComplete="off"
                  autoFocus={false}
                  value={quantity || ''}
                  id="addShoppingCartNumberInput"
                  ref={addShoppingCartNumberInputRef}
                  className="h-full w-full select-none overflow-hidden border-none px-0 text-center text-base font-medium text-[#4DBAC0] shadow-none focus-visible:border-none focus-visible:ring-0"
                  onChange={(e) => {
                    const value = e.target.value;
                    setQuantity(value ? (Number(value) > 999 ? 999 : Number(value)) : 0);
                  }}
                  onBlur={(e) => {
                    const value = e.target.value;
                    setQuantity(value ? (Number(value) >= 4 ? 4 : Number(value) <= 0 ? 1 : Number(value)) : 1);
                  }}
                />
              </div>
              <Image
                src={'/images/product/increase.png'}
                width={20}
                height={20}
                className={`size-5 select-none ${quantity >= 4 ? 'cursor-not-allowed opacity-[24%]' : 'cursor-pointer'}`}
                alt="increaseImage"
                onClick={() => {
                  if (quantity >= 4) {
                    setQuantity(4);
                  } else {
                    setQuantity(quantity + 1);
                  }
                }}
              />
            </div>
            {/* 加入购物车按钮 */}
            <div
              className={`max-md:round-[8px] mr-3 flex h-12 ${buttonWidth ? 'w-[222px]' : 'w-[228px]'} flex-row items-center justify-center rounded-xl max-md:mr-0 max-md:h-11 max-md:w-[43vw] ${
                (productDetail?.productBuySpecification || []).find(
                  (x) => !(selectedAttributeList || []).find((y) => y.attributeNameCN === x.attributeNameCN),
                )?.attributeNameCN
                  ? 'cursor-not-allowed bg-[#9BA1A8] text-[#e4e6e8]'
                  : `cursor-pointer bg-[#4DBAC0] ${darkMode ? 'text-white' : 'text-black'}`
              } text-base font-medium`}
              onClick={() => {
                if (
                  !(productDetail?.productBuySpecification || []).find(
                    (x) => !(selectedAttributeList || []).find((y) => y.attributeNameCN === x.attributeNameCN),
                  )?.attributeNameCN
                ) {
                  addShoppingCart();
                }
              }}
            >
              {t('addToCart')}
            </div>
          </div>
          {/* 收藏按钮 */}
          <div
            className="mr-3 flex size-12 flex-row items-center justify-center rounded-xl bg-[#4dbac01f] max-md:hidden"
            onClick={() => {
              if (!isLoginNew) {
                EM.emit('toLogin');
                return;
              }
              if (!favorited) {
                collectProduct();
              } else {
                //   取消收藏
                cancelCollection();
              }
            }}
          >
            {collectProductLoading || cancelCollectionLoading ? (
              <LoaderIcon className={`${'text-[#4DBAC0]'} size-5 animate-spin`} />
            ) : (
              <Image
                src={favorited ? '/images/goods/love.png' : '/images/goods/love_gray.png'}
                className="size-5 cursor-pointer"
                width={20}
                height={20}
                alt="collectImage"
              />
            )}
          </div>
          {/* 分享按钮 */}
          <div
            className="flex size-12 flex-row items-center justify-center rounded-xl bg-[#4dbac01f] max-md:hidden"
            onClick={() => {
              setOpenShareBox(true);
              if (onOpenShareBox) {
                onOpenShareBox(true);
              }
            }}
          >
            <Image
              src={'/images/product/share.png'}
              className="size-5 cursor-pointer"
              width={20}
              height={20}
              alt="shareImage"
            />
          </div>
        </div>
        {/* 产品详情按钮 */}
        {showMoreButton ? (
          <div className="mt-5 text-center max-md:hidden">
            <Link
              // 在新标签页打开详情页面
              target="_blank"
              href={`/product/${productDetail?.id}`}
              className="cursor-pointer text-sm font-medium text-[#1890FF]"
              onClick={() => {
                if (onClickMoreButton) {
                  onClickMoreButton();
                }
              }}
            >
              {t('moreAboutTheproduct')}
            </Link>
          </div>
        ) : (
          ''
        )}
      </div>
      {/* h5页面的大图弹窗 */}
      <Dialog
        open={showBigImageSwipperInH5}
        onOpenChange={(value) => {
          setShowBigImageSwipperInH5(value);
        }}
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <div className="box-border h-screen w-screen bg-white py-10">
            <BigImageSwiper images={productDetail?.productImages || []} canZoom={true} />
          </div>
        </DialogContent>
      </Dialog>
      {/* 分享 弹窗 */}
      <Dialog
        open={openShareBox}
        onOpenChange={(value) => {
          setOpenShareBox(value);
          if (onOpenShareBox) {
            onOpenShareBox(value);
          }
        }}
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <ShareBox productDetail={productDetail} />
        </DialogContent>
      </Dialog>
      {/* 展示图片 弹窗 */}
      <Dialog
        open={showImageList}
        onOpenChange={(value) => {
          setShowImageList(value);
          if (onOpenShareBox) {
            onOpenShareBox(value);
          }
        }}
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <div className="flex h-[768px] w-[730px] flex-col items-center justify-center rounded-3xl bg-white p-6">
            <ColorListBox colorList={productDetail?.productImages || []} />
          </div>
        </DialogContent>
      </Dialog>
      {showCopySuccess && (
        <div
          className={`fixed left-[50%] top-32 z-[999] box-border flex h-12 w-[351px] translate-x-[-50%] transform flex-row items-center justify-start rounded-xl bg-[#212121] px-3 transition-transform duration-500 max-md:top-14`}
        >
          <Image src={'/images/product/success.png'} className="mr-3 size-6" width={24} height={24} alt="image" />
          <div className="text-base font-medium text-white">{t('copyduccessful')}</div>
        </div>
      )}
      {productIsRemoved && (
        <Dialog open={productIsRemoved} onOpenChange={(value) => setProductRemoved(value)}>
          <DialogTitle></DialogTitle>
          <DialogContent>
            <div className="h-auto w-[428px] rounded-[14px] border bg-white shadow-md max-md:w-screen">
              <div
                style={{ borderColor: 'rgba(60, 60, 67, 0.36)', wordBreak: 'break-word' }}
                className={`flex min-h-[72px] w-full flex-row items-center justify-center border-b border-solid px-11 py-3 text-center text-base font-medium leading-6 text-black`}
              >
                {t('discontinued')}
                {':('}
              </div>
              <div
                className={`flex h-[72px] w-full cursor-pointer flex-row items-center justify-center text-base leading-6 text-[#1890FF]`}
                onClick={() => setProductRemoved(false)}
              >
                {t('understood')}
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default AddShoppingCart;
