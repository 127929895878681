// 登录 , 原型Product page
import CustomModalH5 from '../../../commons/CustomModalH5/index';
// import PhoneNumberInput from './PhoneNumber/index';
import PasswordInputH5 from './PasswordInput/index';
import React, { useEffect, useState } from 'react';
import RegistrationH5 from '../Registration/index';
import EM from '@/shared/utils/EM';
import ForgetPassword from '../../ForgetPassword';
import VerifyCodeForThirdLogin from '../../commons/VerifyCode/ForThirdLogin'; //用于谷歌、FaceBook等三方登录
import VerifyCodeLogin from '../../commons/VerifyCode/OTP';
import OTPLogin from '../../OTPLogin';
import CaptchaH5 from '../../commons/CaptchaH5';
import PhoneNumberInputH5 from './PhoneNumber';

const Login = ({
  triggerIcon,
  isLoginOpenOuter = false,
  setLoginOpenOuter,
  windowReload = false,
}: {
  triggerIcon?: any;
  callback?: any;
  isLoginOpenOuter?: boolean;
  setLoginOpenOuter?: (value: boolean) => void;
  windowReload?: boolean;
}) => {
  const [isLoginOpen, setLoginOpen] = useState<boolean>(isLoginOpenOuter); // 登录弹层是否打开

  const [showRegistrationlayer, setShowRegistrationlayer] = useState<boolean>(false); //注册板块
  const [registrationOpen, setRegistrationOpen] = useState<boolean>(true);

  const [showForgetPasswordLayer, setShowForgetPasswordLayer] = useState<boolean>(false); // 忘记密码板块
  const [forgetPassOpen, setForgetPassOpen] = useState<boolean>(true);

  const [phone, setPhone] = useState<string>('');
  const [contentState, setContent] = useState('phone_number_input'); // 用于控制弹窗内走到下一个流程的内容展示

  const [thirdPlatformType, setThirdPlatformType] = useState<string>('google');
  const [thirdUserId, setThirdUserId] = useState<string>('');
  // 当code=1001时，需要绑定平台用户账号，短信验证码登录逻辑
  // const [smsCode, setSmsCode] = useState<string>();

  const [email, setEmail] = useState<string>(''); // 用于OTP登录

  useEffect(() => {
    console.log({ isLoginOpenOuter });
    setLoginOpen(isLoginOpenOuter);
  }, [isLoginOpenOuter]);

  // console.log('--登录---', isLoginOpen);
  // console.log('--忘记密码---', showForgetPasswordLayer, forgetPassOpen);

  // 重置页面状态()
  const resetLoginPageState = () => {
    console.log('重置');

    if (setLoginOpenOuter) {
      setLoginOpenOuter(false);
    } else {
      setLoginOpen(false);
    }

    setContent('phone_number_input');

    setShowForgetPasswordLayer(false);
    setForgetPassOpen(true);

    setShowRegistrationlayer(false);
    setRegistrationOpen(true);

    setThirdUserId('');
    setPhone('');
  };

  return (
    <div className="h-fit">
      <CustomModalH5
        triggerIcon={triggerIcon || <>open</>}
        isOpen={isLoginOpen}
        setIsOpen={(value: boolean) => {
          if (setLoginOpenOuter) {
            setLoginOpenOuter(value);
          } else {
            setLoginOpen(value);
          }
        }}
        callback={() => {
          // 关闭弹层的回调
          setContent('phone_number_input');
          setThirdUserId('');

          resetLoginPageState();
          if (windowReload) {
            window.location.reload(); // 不可以刷新，体验不好，优化resetLoginPageState
          }
        }}
        width="w-full"
      >
        {contentState === 'phone_number_input' && (
          <PhoneNumberInputH5
            getResult={setPhone}
            getThirdUserId={setThirdUserId}
            setContentNext={() => {
              if (thirdUserId) {
                setContent('captcha'); // 用于三方登录：绑定已有手机号，或者新注册手机号
              } else {
                setContent('password_input');
              }
            }}
            setRegisttarionPage={(phoneInfo: any) => {
              // 关闭登录弹层
              if (setLoginOpenOuter) {
                setLoginOpenOuter(false);
              } else {
                setLoginOpen(false);
              }
              setShowRegistrationlayer(true); // 打开注册弹层
              setTimeout(() => {
                EM.emit('display_register_button', phoneInfo); //  // 打开注册触发按钮
              }, 400);
            }}
            thirdUserId={thirdUserId}
            setThirdPlatformType={setThirdPlatformType}
            goBack={resetLoginPageState}
          />
        )}
        {contentState === 'password_input' && (
          <PasswordInputH5
            phone={phone}
            setIsOpen={(value: boolean) => {
              if (setLoginOpenOuter) {
                setLoginOpenOuter(value);
              } else {
                setLoginOpen(value);
              }
            }}
            goback={() => setContent('phone_number_input')}
            callback={resetLoginPageState}
            showForgetPasswordPage={() => {
              // 关闭登录弹层
              if (setLoginOpenOuter) {
                setLoginOpenOuter(false);
              } else {
                setLoginOpen(false);
              }
              setShowForgetPasswordLayer(true); // 打开忘记密码弹层
              setTimeout(() => {
                setForgetPassOpen(true);
              }, 300);
            }}
            loginByOtp={() => setContent('otp_login_input')}
          />
        )}

        {contentState === 'captcha' && thirdUserId && <CaptchaH5 setStepNext={() => setContent('msg_verify')} />}

        {contentState === 'msg_verify' && thirdUserId && (
          <VerifyCodeForThirdLogin
            phoneNumber={phone}
            goBack={() => setContent('captcha')}
            thirdUserId={thirdUserId}
            thirdPlatformType={thirdPlatformType}
          />
        )}

        {contentState === 'otp_login_input' && ( // 目前没有用这个功能
          <OTPLogin
            getResult={(v: string) => {
              console.log(v);
              setEmail(v);
            }}
            goBack={() => setContent('password_input')}
            setContentNext={() => setContent('otp_login_submit')}
          />
        )}
        {contentState === 'otp_login_submit' && (
          <VerifyCodeLogin email={email} goBack={() => setContent('otp_login_input')} />
        )}
      </CustomModalH5>
      {showRegistrationlayer && (
        <RegistrationH5
          triggerIcon={<></>}
          initialOpen={registrationOpen}
          resetLoginPageState={resetLoginPageState}
          setInitialOpen={(value: any) => {
            setRegistrationOpen(value);
            if (setLoginOpenOuter) {
              setLoginOpenOuter(value);
            } else {
              setLoginOpen(value);
            }
          }}
          thirdUserId={thirdUserId}
          thirdPlatformType={thirdPlatformType}
        />
      )}
      {showForgetPasswordLayer && (
        <ForgetPassword
          callback={resetLoginPageState}
          initialOpen={forgetPassOpen}
          setForgetPassOpen={setForgetPassOpen}
          triggerIcon={<></>}
          // phone={phone}
          goBack={() => {
            setShowForgetPasswordLayer(false);
            if (setLoginOpenOuter) {
              setLoginOpenOuter(true);
            } else {
              setLoginOpen(true);
            }
            setContent('password_input');
          }}
        />
      )}
    </div>
  );
};

export default Login;
