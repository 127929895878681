/* eslint-disable @next/next/no-img-element */
'use client';
import React, { useState, useRef, useMemo, useCallback } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { X, RotateCw } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '@/components/ui/dialog';
import { useRequest } from 'ahooks';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { getUserWebOcrHeader, getUserWebOcrOther } from '@/lib/services/ocr';
// import { getUserWebOcrHeader2, getUserWebOcrOther2 } from '@/lib/services/ocr';
import { NewLoading } from '@/components/commons';
import message from '@/components/commons/Message';
import CustomModal from '@/components/commons/CustomModal';
import PhotoUploadRules from './PhotoUploadRules';
import { loadImage, resizeImage } from './imageTools';
import IconRule from '@/components/user_center/profiles/EditProfileForm/icons/rule';
import { postUserPublicFileUpload } from '@/lib/services/gonggongjiekou';
import AddIcon from './icons/AddIcon';
import CustomModalH5 from '@/components/commons/CustomModalH5';
import useMobileDetect from '@/lib/hooks/useMobileDetect';
const IMAGE_SIZE_LIMIT = 450000;

const reformatDate = function (dateStr: string) {
  // 用点号分割日期字符串
  const [day, month, year] = dateStr.split('.');

  // 重新组合成所需格式
  return `${year}-${month}-${day.padStart(2, '0')}`;
};

// 使用示例
// const date = '02.11.2032';
// console.log(reformatDate(date)); // 输出: "2032-11-02"

export const base64ToFile = function (base64String: string, filename: string) {
  // 解码base64字符串
  const byteString = atob(base64String.split(',')[1]);

  // 提取MIME类型
  const mimeString = base64String.split(',')[0].split(':')[1].split(';')[0];

  // 将二进制字符串转换为字节数组
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // 创建Blob对象
  const blob = new Blob([ab], { type: mimeString });

  // 创建File对象
  return new File([blob], filename, { type: mimeString });
};
// 使用示例
// const base64String = "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAIBAQIBAQICAgICAgICAwUDAwMDAwYEBAMFBwYHBwcGBwcICQsJCAgKCAcHCg0KCgsMDAwMBwkODw0MDgsMDAz/2wBDAQICAgMDAwYDAwYMCAcIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/...";
// const filename = "example.jpg";
// const file = base64ToFile(base64String, filename);
// console.log(file); // 输出File对象

const CropperUpload = (props: {
  [key: string]: any;
  images: (string | null)[];
  setImages: React.Dispatch<React.SetStateAction<(string | null)[]>>;
  classNames?: any;
}) => {
  const t = useTranslations('PersonalInfoSubmit');
  const { isMobile } = useMobileDetect();

  const {
    onChange,
    setOcrIIN,
    setOcrDocNo,
    setValidityPeriod,
    images,
    setImages,
    setUploadFail,
    setOcrVerifyFail,
    classNames,
  } = props;
  const { labelClass = '' } = classNames || {};

  const [currentCropIndex, setCurrentCropIndex] = useState<number>(0);
  const [currentCropImage, setCurrentCropImage] = useState<string | ArrayBuffer | null>();
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState<string>('');
  const cropperRef = useRef<any>(null);

  // 文件上传  ====> 这里后续应该改为（或者加上）OCR图片识别
  const { loading: loading1, runAsync: toUpload } = useRequest(postUserPublicFileUpload, {
    manual: true,
    onSuccess(res) {
      if (res.code === 0) {
        setUploadFail?.(false);
      } else {
        setUploadFail?.(true);
        // message.error(res.msg);
      }
    },
    onError() {
      setUploadFail?.(true);
    },
  });

  // OCR 正面识别
  const { loading: loading2, runAsync: OcrHeader } = useRequest(getUserWebOcrHeader, {
    //  runAsync: OcrHeader
    manual: true,
    onSuccess(res) {
      if (res.code !== 0) {
        message.error(res.msg);
      } else {
        setOcrVerifyFail(true);
      }
    },
    onError() {
      setOcrVerifyFail(true);
      // message.error(err);
    },
  });

  // OCR 背面识别
  const { loading: loading3, runAsync: OcrOthers } = useRequest(getUserWebOcrOther, {
    // runAsync: OcrOthers
    manual: true,
    onSuccess(res) {
      if (res.code !== 0) {
        message.error(res.msg);
      } else {
        setOcrVerifyFail(true);
      }
    },
    onError() {
      setOcrVerifyFail(true);
      // message.error(err);
    },
  });

  const loading = useMemo(() => {
    return loading1 || loading2 || loading3;
  }, [loading1, loading2, loading3]);

  const [isRuleModalOpen, setRuleModalOpen] = useState(false);
  const [isRuleChecked, setRuleChecked] = useState<boolean[]>([false, false]);
  const fileInputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const handleLabelClick = (index: number) => {
    setCurrentCropIndex(index);
    console.log(isRuleChecked, index, isRuleChecked[index]);
    // setRuleModalOpen(true);
    // fileInputRefs.current[index]?.click();
    if (!isRuleChecked[index]) {
      setRuleModalOpen(true);
    } else {
      fileInputRefs.current[index]?.click();
    }
  };
  const handleRuleConfirm = useCallback(
    (currentCropIndex: number) => {
      console.log(currentCropIndex, isRuleChecked);

      if (isRuleChecked.filter((ele) => ele).length < 2) {
        fileInputRefs.current[currentCropIndex]?.click(); // 先调起文件选择
      }

      setRuleChecked((v) => {
        // 再做状态处理
        v[currentCropIndex] = true;
        return v;
      });
      setRuleModalOpen(false);
    },
    [isRuleChecked],
  );

  const handleFileChange = (e: any, index: number) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setCurrentCropImage(reader.result);
        setCurrentCropIndex(index);
        setIsCropModalOpen(true);
      };
      reader.readAsDataURL(file);
    }
    // 处理完后清空 input 的 value
    e.target.value = '';
  };

  const handleCropComplete = async () => {
    if (cropperRef.current && cropperRef.current?.cropper) {
      const croppedCanvas = cropperRef.current?.cropper.getCroppedCanvas();

      const dataUrl = croppedCanvas.toDataURL();

      let file = base64ToFile(dataUrl, `wk-test-${currentCropIndex}.jpg`);

      // 压缩处理
      const compressImage = async function (fileOrigin: any) {
        if (fileOrigin.size > IMAGE_SIZE_LIMIT) {
          console.log('压缩----', fileOrigin.size);

          const img = await loadImage(fileOrigin);

          let RATIO = Math.ceil(fileOrigin.size / IMAGE_SIZE_LIMIT);
          // console.log({ RATIO });

          if (RATIO > 4 && RATIO <= 10) {
            RATIO = 4;
          }
          if (RATIO > 10) {
            RATIO = Math.ceil(Math.sqrt(RATIO));
          }

          const width = img.width / RATIO;
          const height = img.height / RATIO;

          const res = await resizeImage(img, width, height); //blob

          file = base64ToFile(res.url, `wk-test-${currentCropIndex}.jpg`);

          if (file.size > IMAGE_SIZE_LIMIT) {
            await compressImage(file);
          }
        }
      };
      await compressImage(file); // await 必须加

      const res = await toUpload({}, file);
      // console.log({ res });
      if (res.code === 0) {
        if (currentCropIndex === 0) {
          const result = await OcrHeader({ imageUrl: res.data });
          console.log(result);
          if (result.code === 0) {
            try {
              // setOcrHeaderValid(true);
              const { idcardNumber } = result.data as any;
              // form.setValue('name', name);
              // form.setValue('iin', idcardNumber);
              setOcrIIN(idcardNumber?.replace(/\D/g, '') || '');
              setOcrVerifyFail(false);
            } catch (error) {
              console.log(error);
              setOcrVerifyFail(true);
            }
          } else {
            message.error(result.msg);
            // return;
          }
        }
        if (currentCropIndex === 1) {
          const result = await OcrOthers({ imageUrl: res.data });
          // console.log(result);
          if (result.code === 0) {
            // setOcrOtherValid(true);
            try {
              const { cardNo, valid } = result.data as any; // valid "03.11.2022 - 02.11.2032"
              // form.setValue('documentNumber', cardNo);
              setOcrDocNo(cardNo?.replace(/\D/g, '') || '');
              if (valid.length > 10) {
                let date = valid.slice(-10); //.replaceAll('.', '-');
                date = reformatDate(date);
                console.log(date);
                setValidityPeriod(date);
              }
              setOcrVerifyFail(false);
            } catch (error) {
              console.log(error);
              setOcrVerifyFail(true);
            }
          } else {
            message.error(result.msg);
            // return;
          }
        }
        setImages((prev) => {
          const newImages = [...prev];
          newImages[currentCropIndex as any] = res.data;
          onChange(newImages);
          return newImages;
        });
      }
      // else {
      //   setImages((prev) => {
      //     const newImages = [...prev];
      //     newImages[currentCropIndex as any] = url;
      //     onChange(newImages);
      //     return newImages;
      //   });
      // }

      setIsCropModalOpen(false);
      // setCurrentCropIndex(0);
    }
  };

  const handleRemoveImage = (index: any) => {
    setImages((prev) => {
      const newImages = [...prev];
      newImages[index] = null;
      onChange(newImages);
      return newImages;
    });
  };

  const handlePreviewImage = (index: any) => {
    setPreviewImage(images[index] as any);
    setIsPreviewModalOpen(true);
  };

  const handleRotate = () => {
    if (cropperRef.current && cropperRef.current.cropper) {
      cropperRef.current.cropper.rotate(90);
    }
  };

  console.log(fileInputRefs.current);
  console.log(isCropModalOpen);
  console.log({ currentCropImage });

  return (
    <div className="relative rounded-2xl bg-[#f5f6f6] pb-8 pt-1">
      <div className="absolute right-0 top-6">
        {!isMobile && (
          <CustomModal
            isOpen={isRuleModalOpen}
            setIsOpen={setRuleModalOpen}
            callback={() =>
              setRuleChecked((v) => {
                v[currentCropIndex] = true;
                return v;
              })
            }
            triggerIcon={
              <div className="hidden">
                <IconRule width="20" height="20" />
              </div>
            }
          >
            <PhotoUploadRules currentCropIndex={currentCropIndex} setRuleChecked={handleRuleConfirm} />
          </CustomModal>
        )}

        {isMobile && (
          <CustomModalH5
            isOpen={isRuleModalOpen}
            setIsOpen={setRuleModalOpen}
            callback={() =>
              setRuleChecked((v) => {
                v[currentCropIndex] = true;
                return v;
              })
            }
            triggerIcon={
              <div className="hidden">
                <IconRule width="20" height="20" />
              </div>
            }
          >
            <PhotoUploadRules
              goBack={() => {
                setRuleModalOpen(false);
              }}
              currentCropIndex={currentCropIndex}
              setRuleChecked={handleRuleConfirm}
            />
          </CustomModalH5>
        )}
      </div>
      <h2 className={`mb-4 text-sm ${labelClass}`}>{t('uploadtext')}</h2>
      <div className="flex space-x-4">
        {images.map((image, index) => (
          <div key={index} className="relative">
            <input
              ref={(el) => (fileInputRefs.current[index] = el as any)}
              type="file"
              accept="image/*"
              onChange={(e) => handleFileChange(e, index)}
              className="hidden"
            />
            {image ? (
              <div className="relative h-24 w-24 overflow-hidden rounded-lg bg-white">
                <Image
                  width="40"
                  height="40"
                  src={image}
                  alt={`Uploaded document ${index + 1}`}
                  className="h-full w-full cursor-pointer object-cover"
                  onClick={() => handlePreviewImage(index)}
                />
                <button
                  onClick={() => handleRemoveImage(index)}
                  className="absolute right-1 top-1 rounded-full bg-[#f5f6f6] p-1"
                >
                  <X size={16} />
                </button>
              </div>
            ) : (
              <div>
                <label
                  onClick={() => handleLabelClick(index)}
                  className="flex h-24 w-24 cursor-pointer flex-col items-center justify-around rounded-lg border-2 border-dashed border-[#f5f6f6] bg-white pb-2"
                >
                  <span className="text-center text-[13px] text-[#9ba1a8]">{index === 0 ? t('front') : t('back')}</span>
                  <AddIcon width={'18'} height={'18'} />
                </label>
              </div>
            )}
          </div>
        ))}
      </div>

      <Dialog
        open={isCropModalOpen}
        onOpenChange={() => {
          setIsCropModalOpen(!isCropModalOpen);
        }}
      >
        <DialogContent className="min-w-[700px] rounded-3xl bg-white p-10 max-md:h-screen max-md:w-full max-md:min-w-0 max-md:p-0 max-md:py-10 sm:max-w-[980px] sm:rounded-2xl">
          <DialogHeader className="mx-10 my-4">
            <div className="flex w-full justify-between">
              <DialogTitle>{t('croppicture')}</DialogTitle>
              <button
                onClick={handleRotate}
                className="mr-[200px] rounded-full p-2 transition-colors hover:bg-gray-100"
              >
                <RotateCw className="h-5 w-5 text-gray-600" />
              </button>
            </div>
          </DialogHeader>
          {currentCropImage && (
            <div className="flex items-center justify-center p-6 max-md:px-0 max-md:pt-0">
              <div className="h-[400px] w-[600px] max-md:h-[300px] max-md:w-full">
                <Cropper
                  ref={cropperRef}
                  src={currentCropImage as any} // ArrayBuffer
                  style={{ height: '100%', width: '100%', borderRadius: '10px', overflow: 'hidden' }}
                  aspectRatio={1587 / 1000} // 取1.55 和 1.66 的中间值，微调 (1.587)
                  guides={true}
                />
              </div>
              {currentCropIndex === 0 && (
                <div className="ml-4 flex h-[400px] w-64 flex-col items-center justify-between px-0 max-md:hidden">
                  <span className="pb-3 pl-3 text-sm">{t('cropRule')}</span>
                  <img
                    src="/images/cropDemos/ft1.png"
                    alt=""
                    className="h-[150px] w-auto cursor-pointer rounded-lg object-cover"
                  />
                  <Image
                    width="20"
                    height="30"
                    src="/images/cropDemos/jt.png"
                    alt=""
                    className="my-3 h-6 w-5 cursor-pointer object-fill"
                  />
                  <img
                    src="/images/cropDemos/ft2.png"
                    alt=""
                    className="h-[150px] w-auto cursor-pointer rounded-lg object-cover"
                  />
                </div>
              )}
              {currentCropIndex === 1 && (
                <div className="ml-4 flex h-[400px] w-64 flex-col items-center justify-between px-0 max-md:hidden">
                  <span className="pb-3 pl-3 text-sm">{t('cropRule')}</span>
                  <img
                    src="/images/cropDemos/bk1.png"
                    alt=""
                    className="h-[150px] w-auto cursor-pointer rounded-lg object-cover"
                  />
                  <Image
                    width="20"
                    height="30"
                    src="/images/cropDemos/jt.png"
                    alt=""
                    className="my-3 h-6 w-5 cursor-pointer object-fill"
                  />
                  <img
                    src="/images/cropDemos/bk2.png"
                    alt=""
                    className="h-[150px] w-auto cursor-pointer rounded-lg object-cover"
                  />
                </div>
              )}
            </div>
          )}
          <DialogFooter className="mb-10 mr-10 max-md:mr-0 max-md:px-4">
            <button
              className="rounded-lg bg-[#cce8e9] px-4 py-1 text-[#4DBAC0] outline-none hover:opacity-90 max-md:mb-4 max-md:py-2"
              onClick={() => {
                setIsCropModalOpen(false);
              }}
            >
              {t('close')}
            </button>
            <button
              className={`min-w-24 rounded-lg bg-[#4DBAC0] px-4 py-1 text-black outline-none hover:opacity-80 max-md:mb-4 max-md:py-2 ${loading ? 'bg-[var(--primary-clor-gray3)] !text-[var(--theme-gray-1)]' : ''}`}
              onClick={handleCropComplete}
              disabled={loading}
            >
              {loading ? <NewLoading type="special" /> : t('confirm')}
            </button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={isPreviewModalOpen} onOpenChange={setIsPreviewModalOpen}>
        <DialogContent className="min-w-[700px] rounded-2xl bg-white p-10 max-md:min-w-0 max-md:rounded-none max-md:shadow-md sm:min-w-[625px]">
          <DialogHeader>
            <DialogTitle className="px-10 py-2">{t('preview')}</DialogTitle>
          </DialogHeader>
          <div className="px-5 pb-5">
            <Image width="240" height="240" src={previewImage} alt="Preview" className="m-auto h-auto w-full" />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CropperUpload;
