'use client';

import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { useRequest } from 'ahooks';
import { getProductPublicWebRecommendProductList } from '@/lib/services/zhuye';
import { Goods, NewLoading } from '@/components/commons';
import { useRouter } from 'next/router';
import { useDarkModeProvider } from '@/lib/context';

const Recommend = ({
  inCartPage = false,
  inSearchPage = false,
  inUserCenter = false,
  handleCollection,
  collectData,
  selectedGoods,
}: {
  inCartPage?: boolean;
  inSearchPage?: boolean;
  inUserCenter?: boolean;
  handleCollection?: (value: boolean, item: API.WebProductItemVo) => void;
  collectData?: any[];
  selectedGoods?: any;
}) => {
  const { pathname } = useRouter();
  const t = useTranslations('Home');
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(0);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [renderData, setRenderData] = useState<any[]>([]);
  // 推荐商品列表
  const { loading } = useRequest(
    () =>
      getProductPublicWebRecommendProductList({
        pageNo: pageNo,
        pageSize: pageSize,
      }),
    {
      manual: !(pageSize > 0),
      cacheKey: 'home-recommend',
      refreshDeps: [pageNo, pageSize],
      onSuccess: (response) => {
        const oldData = [...renderData];
        const newData = oldData.concat(response?.data?.list || []);
        setRenderData(newData);
        setHasNextPage(response?.data?.hasNextPage || false);
      },
    },
  );

  const { darkMode } = useDarkModeProvider();

  // 每行显示的个数
  const [rowQuantity, setRowQuantity] = useState<number>(0);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  // 120px是左右边距，固定布局
  useEffect(() => {
    setRenderData([]);
    const newRowQuantity = Math.floor(
      (inUserCenter ? width - 120 - 120 - 300 - 24 - 24 - 24 : width - 120 - 120) / (180 + 12 + 12),
    );
    setRowQuantity(newRowQuantity);
    setPageSize(newRowQuantity * (inCartPage || inSearchPage ? 1 : inUserCenter ? 2 : 4)); // 1、4是行数
    setPageNo(1);
  }, [inCartPage, inSearchPage, inUserCenter, width]);

  useEffect(() => {
    if (selectedGoods) {
      setRenderData((renderData || []).map((x) => (x.productId === selectedGoods.productId ? selectedGoods : x)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGoods]);

  return (
    <section className={inUserCenter ? '' : `box-border w-full py-6 ${darkMode ? 'bg-black' : 'bg-[#f5f6f6]'}`}>
      <div className={inUserCenter ? '' : 'container'}>
        <h2
          className={`mb-6 text-lg font-semibold ${renderData && !!renderData.length ? 'block' : 'hidden'} leading-6 ${darkMode ? 'text-white' : 'text-black'}`}
        >
          {t('recommend')}
        </h2>
        <div
          className={`grid justify-between gap-6`}
          style={{ gridTemplateColumns: `repeat(${rowQuantity}, minmax(0, 1fr))` }}
        >
          {(renderData || [])
            .map((goodsItem) => {
              return {
                ...goodsItem,
                favorited:
                  pathname === '/favorites'
                    ? !!(collectData || []).find((x) => x.productId === goodsItem.productId)
                    : goodsItem.favorited,
              };
            })
            .map((goodsItemData) => {
              return (
                <div key={goodsItemData.productId} className="w-[180px]">
                  <Goods
                    item={{
                      ...goodsItemData,
                      favorited:
                        goodsItemData.productId === selectedGoods?.productId
                          ? selectedGoods?.favorited
                          : goodsItemData.favorited,
                    }}
                    handleCollection={(value, goods) => {
                      if (handleCollection) {
                        handleCollection(value, goods);
                      }

                      const newData = renderData ? [...renderData] : [];
                      if (value) {
                        const index = (newData || []).findIndex((goodItem) => goodItem.productId === goods.productId);
                        if (index > -1) {
                          newData[index].favorited = true;
                        }
                      } else {
                        const index = (newData || []).findIndex((goodItem) => goodItem.productId === goods.productId);
                        if (index > -1) {
                          newData[index].favorited = false;
                        }
                      }
                      setRenderData(newData);
                    }}
                  />
                </div>
              );
            })}
        </div>
        {loading ? (
          <div className="mt-6 flex h-12 w-full flex-row items-center justify-center">
            <NewLoading />
          </div>
        ) : hasNextPage && !inCartPage && !inSearchPage && !inUserCenter ? (
          <div className="mt-6 flex h-12 w-full flex-row items-center justify-center">
            <div
              onClick={() => {
                if (loading) {
                  return;
                }
                setPageNo(pageNo + 1);
              }}
              className={`flex h-12 w-[384px] cursor-pointer flex-row items-center justify-center rounded-xl text-base font-medium ${darkMode ? 'bg-[#3a969b3d] text-[#3A969B]' : 'bg-[#4dbac03d] text-primary'}`}
            >
              {t('showMore')}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </section>
  );
};

export default Recommend;
