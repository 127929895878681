import { X } from 'lucide-react';
// import EM from '@/shared/utils/EM';
// import { useEffect } from 'react';

const CustomModalForPolicyH5 = ({
  children: Content,
  triggerIcon: TriggerIcon,
  closeIcon: CloseIcon = X,
  isOpen = false,
  setIsOpen,
  callback,
  width = 'w-fit',
}: {
  children?: any;
  triggerIcon?: any;
  closeIcon?: any;
  isOpen?: any;
  setIsOpen: any;
  width?: 'w-fit' | 'w-full';
  callback?: () => void;
}) => {
  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    setIsOpen(false);
    if (callback) {
      callback(); // 关闭弹层的回调
    }
  };

  const handleBackgroundClick = (e: any) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  const handleContentClick = (e: any) => {
    e.stopPropagation();
  };

  // // 对外暴露关闭弹窗方法，舍弃，会接收所有的弹层消息，除非在props里还用id区分
  // useEffect(() => {
  //   EM.addListener('CLOSE_MODAL', closeModal);
  //   return () => {
  //     EM.removeListener('CLOSE_MODAL', closeModal);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   EM.addListener('OPEN_MODAL', openModal);
  //   return () => {
  //     EM.removeListener('OPEN_MODAL', openModal);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <>
      {TriggerIcon ? (
        <div onClick={openModal} className={`${width} cursor-pointer`}>
          {TriggerIcon}
        </div>
      ) : (
        <button onClick={openModal} className="rounded bg-blue-500 px-4 py-0 text-white hover:bg-blue-600">
          Open Modal
        </button>
      )}

      {isOpen && (
        <div
          className="CustomModalForPolicyH5 fixed inset-0 bottom-0 left-0 z-[9999] flex h-full items-start justify-center bg-gray-500 bg-opacity-30 pt-[10vh]"
          onClick={handleBackgroundClick}
        >
          <div
            className="absolute bottom-0 left-0 h-[90vh] w-full overflow-hidden rounded-t-3xl border bg-transparent"
            onClick={handleContentClick}
          >
            <div className="absolute right-2 top-2 z-[1500] cursor-pointer" onClick={closeModal}>
              <CloseIcon />
            </div>
            {Content}
          </div>
        </div>
      )}
    </>
  );
};

export default CustomModalForPolicyH5;
