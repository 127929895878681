// 注册 , 原型Product page Registration
// import CustomModal from '../../../commons/CustomModal/index';
import PasswordSet from './PasswordSet/index';
import React, { useEffect, useState } from 'react';
import PersonalInfoSubmit from '../../PersonalInfoSubmit/PersonalInfoForm';
import EM from '@/shared/utils/EM';
import CaptchaH5 from '../../commons/CaptchaH5/index';
import TwoFactorAuthByEmail from '../../TwoFactorAuthByEmail/Content';
import VerifyCodeForRegistrationH5 from '../../commons/VerifyCodeH5/ForRegistration';
import CustomModalH5 from '@/components/commons/CustomModalH5';
import DoubleVerifySuccess from '../../TwoFactorAuthByEmail/DoubleVerifySuccess';

const Registration = function ({
  triggerIcon,
  resetLoginPageState,
  initialOpen, // setInitialOpen,
  thirdUserId,
  thirdPlatformType,
}: {
  triggerIcon?: any;
  resetLoginPageState?: any;
  initialOpen?: any;
  setInitialOpen?: any;
  thirdUserId?: any;
  thirdPlatformType?: string;
}) {
  const [contentState, setContent] = useState('captcha'); // 用于控制弹窗内走到下一个流程的内容展示
  console.log({ initialOpen });
  const [isOpen, setIsOpen] = useState<boolean>(initialOpen || false);
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [pointJson, setPointJson] = useState<any>();
  const [ticket, setTicket] = useState<string>();
  const [phoneInfo, setPhoneInfo] = useState<{ countryCode: string; phoneNumber: string; country: string }>({} as any); // 用途注册页面初始化

  // 打开注册触发按钮
  useEffect(() => {
    const fn = (e: any) => {
      console.log(e);
      setPhoneInfo(e as any);
      setPhoneNumber(e.countryCode + e.phoneNumber);
      setIsOpen(true);
    };
    EM.addListener('display_register_button', fn);
    return () => {
      EM.removeListener('display_register_button', fn);
    };
  }, []);

  return (
    <CustomModalH5
      triggerIcon={triggerIcon || <>open</>}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      callback={() => {
        // 关闭弹层的回调
        resetLoginPageState?.();
        if (contentState === 'two_factor_authentication') {
          // 关闭双重验证，需要刷新页面
          window.location.reload();
        }
      }}
      classNmases={{
        contentClassName: contentState === 'captcha' ? '!px-0' : '',
      }}
    >
      {contentState === 'captcha' && (
        <CaptchaH5 getResult={setPointJson} setStepNext={() => setContent('msg_verify')} />
      )}
      {contentState === 'msg_verify' && (
        // 设置密码提交就会注册成功，就会转为登录状态。怎么继续后面的流程？
        <VerifyCodeForRegistrationH5
          phoneNumber={phoneNumber}
          token={pointJson?.token}
          setContent={() => setContent('set_password')}
          getResult={setTicket}
          goBack={() => setContent('captcha')}
          // thirdUserId={thirdUserId}
        />
      )}
      {contentState === 'set_password' && (
        <PasswordSet
          phoneNumber={phoneNumber?.replace(/(?!^\+)\D/g, '')}
          ticket={ticket}
          abbrCountry={phoneInfo.country}
          // setIsOpen={setIsOpen}
          setNext={() => setContent('personal_info_submit')}
          thirdUserId={thirdUserId}
          thirdPlatformType={thirdPlatformType}
          // goBack={() => setContent('msg_verify')}
          goBack={() => {
            setIsOpen(false);
            resetLoginPageState?.();
          }}
        />
      )}
      {contentState === 'personal_info_submit' && (
        <PersonalInfoSubmit
          goBack={() => {
            setIsOpen(false);
            resetLoginPageState?.();
          }}
          setStepNext={() => setContent('double_verify_success')}
        />
      )}

      {contentState === 'two_factor_authentication' && <TwoFactorAuthByEmail />}

      {contentState === 'double_verify_success' && <DoubleVerifySuccess />}
    </CustomModalH5>
  );
};

export default Registration;
