import React, { useMemo, useState } from 'react';
import { useRequest } from 'ahooks';
import { useLocale, useTranslations } from 'next-intl';
import message from '@/components/commons/Message/index';
import { getPasswordValidationRes, validateEmailOrMobile, validatePasswordIntel } from '../../utils';
import AlertIcon from '@/components/cart/AlertIcon';
import { NewLoading } from '@/components/commons';
import InputField from '@/components/commons/InputField';
import { postUserPublicForgetPasswordTwo } from '@/lib/services/gonggongjiekou';
import EM from '@/shared/utils/EM';
import EyeOff from '../../icons/EyeOff';
import Eye from '../../icons/Eye';
import PasswordValidation from '../../ChangePassword/PasswordReSet/PasswordValidation';

const PasswordInput = ({ setIsOpen, emailOrMobile, ticket }: any) => {
  const t = useTranslations('ResetPassword');
  const currentLocale = useLocale();

  const [passwordValidationRes, setPasswordValidationRes] = useState<{
    lengthSufficient: boolean;
    withCapital: boolean;
    withNumber: boolean;
    withSpecial: boolean;
    withoutLatin: boolean;
    withoutSpace: boolean;
  }>({
    lengthSufficient: false,
    withCapital: false,
    withNumber: false,
    withSpecial: false,
    withoutLatin: false,
    withoutSpace: false,
  });

  // 忘记密码第二步
  const { loading, run: postForgetPassword } = useRequest(postUserPublicForgetPasswordTwo, {
    manual: true,
    onSuccess(res) {
      if (res.code === 0) {
        setTimeout(() => {
          setIsOpen(false);
          EM.emit('notLogin', true);
        }, 1500);
      } else {
        message.error(res.msg || t('failed'), 2000);
      }
    },
  });

  // const initRef = useRef(false);

  const [password, setPassword] = useState('');
  const [replayPassword, setReplayPassword] = useState('');

  const [isSame, setIsSame] = useState<boolean>(true);
  const [blur, manageBlur] = useState<number>(1);
  const [validateBeforeSubmit, setValidateBeforeSubmit] = useState<boolean>(false);

  const [passwordVerifyText, setPasswordVerifyText] = useState('');
  const [passwordVerifyText2, setPasswordVerifyText2] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const togglePasswordVisibility = (field: string) => {
    if (field === 'password') {
      setShowPassword(!showPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const handlePassChange = (e: any) => {
    // const value = e.target.value.replace(/\D/g, '')
    const value = e.target.value.trim();
    setPassword(value);
    const validateRes = validatePasswordIntel(value);
    setPasswordValidationRes(getPasswordValidationRes(value));

    setPasswordVerifyText(validateRes[currentLocale as 'en' | 'ru' | 'kk']);
    setValidateBeforeSubmit(false);
  };

  const handleReplayPassChange = (e: any) => {
    console.log('重复输入密码');

    // const value = e.target.value.replace(/\D/g, '')
    const value = e.target.value.trim();
    setReplayPassword(value);

    const validateRes = validatePasswordIntel(value);
    setPasswordVerifyText2(validateRes[currentLocale as 'en' | 'ru' | 'kk']);
    if (value.length >= password.length) {
      // setIsSame(value === password);
    }
    setValidateBeforeSubmit(false);
  };

  const disabled = useMemo(() => {
    console.log(!isSame, loading);

    if (passwordVerifyText) return true;

    return !isSame || loading;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSame, loading, blur, passwordVerifyText]);

  return (
    <div className="relative inset-0 flex min-w-fit items-center justify-center bg-opacity-50">
      <div className="w-[420px] rounded-3xl bg-white p-[20px] shadow-lg max-md:w-[360px]">
        <h2 className="mb-4 text-center text-[16px] font-bold">{t('createNewPass')}</h2>

        <form id="forget-password-reset">
          <div className="w-[380px] space-y-4 max-md:w-full">
            <div className="relative">
              <InputField
                label={t('password')}
                type={showPassword ? 'text' : 'password'}
                placeholder="Qwerty123@"
                labelColor="#000"
                value={password}
                onChange={handlePassChange}
                onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                  const value = e.target.value;
                  if (replayPassword) {
                    setIsSame(value === replayPassword);
                  }
                }}
                onFocus={() => {
                  // setIsSame(false);
                  manageBlur((v) => v + 1);
                }}
              />

              <button
                type="button"
                onClick={() => togglePasswordVisibility('password')}
                className="absolute right-4 top-[50%] -translate-y-[50%] text-[#9ba1a8]"
              >
                {showPassword ? <Eye /> : <EyeOff />}
              </button>
            </div>

            {passwordVerifyText && (
              <div className="flex items-center px-0 py-0" role="alert">
                <AlertIcon />
                <div className="ml-1 text-[12px] text-[#ff3141]">{passwordVerifyText}</div>
              </div>
            )}

            <div className="relative">
              <InputField
                label={t('repeatpassword')}
                type={showConfirmPassword ? 'text' : 'password'}
                placeholder="Qwerty123@"
                labelColor="#000"
                value={replayPassword}
                onChange={handleReplayPassChange}
                onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                  const value = e.target.value;
                  if (password) {
                    setIsSame(value === password);
                  }
                }}
                onFocus={() => {
                  // setIsSame(false);
                  manageBlur((v) => v + 1);
                }}
                onMouseOut={() => manageBlur((v) => v + 1)}
              />
              <button
                type="button"
                onClick={() => togglePasswordVisibility('confirm')}
                className="absolute right-4 top-[50%] -translate-y-[50%] text-[#9ba1a8]"
              >
                {showConfirmPassword ? <Eye /> : <EyeOff />}
              </button>
            </div>

            {passwordVerifyText2 && (
              <div className="flex items-center px-0 py-0" role="alert">
                <AlertIcon />
                <div className="ml-1 text-[12px] text-[#ff3141]">{passwordVerifyText2}</div>
              </div>
            )}

            {!isSame && !passwordVerifyText2 && (
              <div className="flex items-center px-0 py-0" role="alert">
                <AlertIcon />
                <div className="ml-1 text-[12px] text-[#ff3141]"> {t('notsametext')}</div>
              </div>
            )}

            <div className="py-3">
              <PasswordValidation validationState={passwordValidationRes} validateBeforeSubmit={validateBeforeSubmit} />
            </div>

            <button
              className={`w-full cursor-pointer rounded-xl bg-[#4DBAC0] py-3 text-black transition duration-150 ease-in-out hover:opacity-85`}
              disabled={disabled}
              onClick={() => {
                if (Object.values(passwordValidationRes).some((ele) => !ele)) {
                  setValidateBeforeSubmit(true);
                  return;
                }

                if (ticket && !disabled) {
                  const type = validateEmailOrMobile(emailOrMobile as string);
                  if (type === 'mobile') {
                    postForgetPassword({
                      mobile: emailOrMobile.replace(/(?!^\+)\D/g, ''),
                      ticket, // 验证短信验证码后返回的ticket
                      newPassword: password,
                    });
                  }
                  if (type === 'email') {
                    postForgetPassword({
                      email: emailOrMobile.trim(),
                      ticket, // 验证短信验证码后返回的ticket
                      newPassword: password,
                    });
                  }
                }
              }}
              onMouseOver={() => {
                manageBlur((v) => v + 1);
              }}
              type="button"
            >
              {loading ? <NewLoading type="special" /> : t('save')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

// ${disabled ? '!bg-[var(--primary-clor-gray3)] !text-[var(--theme-gray-1)]' : ''
export default PasswordInput;
