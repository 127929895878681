'use client';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { Form, FormControl, FormField, FormItem } from '@/components/ui/form';
import { useForm } from 'react-hook-form';
import CropperUpload from '../CropperUpload/index';
import { useRequest } from 'ahooks';
import { useLocale, useTranslations } from 'next-intl';
import { getUserWebCustomerProfile, postUserWebProfileEdit } from '@/lib/services/gerenzhongxin';
import { NewLoading } from '@/components/commons';
import { useAuthProvider } from '@/lib/context/AuthProvider';
import InputField from '@/components/commons/InputField';
import TanIcon from '../icons/Tan';
import CustomFormItem from '../comps/CustomFormItem';
import { useCartProvider } from '@/lib/context/CartProvider';
import { wait } from '@/shared/utils/tools';
import CustomDatePicker from '@/components/user_center/profiles/EditProfileForm/DatePicker/CustomDatepicker';
import { formatDocumentNo, formatPhoneNumberFull, validateEmailOrMobile, validateUserNameIntel02 } from '../../utils';
import storage from '@/shared/utils/storage';
import CustomInput from '../comps/CustomInput';
import { message } from 'antd';
import AlertIcon from '@/components/cart/AlertIcon';
import { useFormattedInput } from '../comps/useFormattedInputHook';
import HeaderBackH5 from '../../H5/common/HeaderBackH5';
import { getUserPublicEmailExist } from '@/lib/services/gonggongjiekou';
import { Timeout } from 'ahooks/lib/useRequest/src/types';
// import CustomDatePickerH5 from '@/components/user_center/profiles/EditProfileForm/DatePicker/CustomDatepickerH5';
// import useMobileDetect from '@/lib/hooks/useMobileDetect';

const NamePlaceHolers = {
  ru: 'Иван Иванов',
  en: 'Ivan Ivanov',
  kk: 'Иван Иванов',
};

const compareStrings = function (str1: string, str2: string) {
  // 如果长度不同，直接返回false
  if (str1.length !== str2.length) {
    return false;
  }

  let diffCount = 0;

  // 遍历比较每个字符
  for (let i = 0; i < str1.length; i++) {
    if (str1[i] !== str2[i]) {
      diffCount++;
      // 如果差异超过2个字符，提前返回false
      if (diffCount > 0) {
        // 改为完全匹配
        return false;
      }
    }
  }

  // 返回最终比较结果
  return true;
};

// react-hook-form 表单
const PersonalInfoForm = ({ setStepNext, goBack }: { setStepNext: () => void; goBack?: () => void }) => {
  const t = useTranslations('PersonalInfoSubmit');

  const currentLocale = useLocale();

  // const { isMobile } = useMobileDetect();

  // 光标处理
  const handleFormattedChange = useFormattedInput();

  const { updateEmabledStatus } = useAuthProvider();
  const { shoppingCartSynchronizationAfterLogin } = useCartProvider();

  // const currentLocale = useLocale();
  // console.log({ currentLocale });

  const initRef = useRef(true);

  // OCR检测iin和documentNumber是否匹配（输入的和识别的比较）
  const [isIINMatch, setIINMatch] = useState(false);
  const [OcrIIN, setOcrIIN] = useState('');
  const [isDocNoMatch, setDocNoMatch] = useState(false);
  const [OcrDocNo, setOcrDocNo] = useState('');

  const [images, setImages] = useState<(string | null)[]>([null, null]);
  const [validityPeriod, setValidityPeriod] = useState<string | null>('');

  const [uploadFail, setUploadFail] = useState(false);
  const [ocrVerifyFail, setOcrVerifyFail] = useState(false);

  const form = useForm({
    defaultValues: {
      name: '',
      // phone: '',
      email: '',
      iin: '',
      documentNumber: '',
      validityPeriod: '',
      documentImage: [],
    },
    mode: 'onChange', // This enables real-time validation
  });
  const { formState, setValue, watch, trigger } = form;
  const { isValid, isDirty } = formState;

  const [phone, setPhone] = useState<string>();
  const fillFormFields = (data: any) => {
    const { name, email, iin, documentNumber, documentImage, validityPeriod } = data;
    setValue('name', name, { shouldDirty: true });
    setValue('email', email, { shouldDirty: true });
    setValue('iin', iin, { shouldDirty: true });
    setValue('documentNumber', documentNumber, { shouldDirty: true });
    setValue(
      'documentImage',
      documentImage?.map((ele: any) => ele.photo, { shouldDirty: true }),
    );
    setValue('validityPeriod', validityPeriod, { shouldDirty: true });
    setImages(documentImage?.map((ele: any) => ele.photo) || [null, null]);
  };

  // 监听所有表单字段
  const formData = watch();

  // 比较表单数据和外部数据
  useEffect(() => {
    const { iin, documentNumber } = formData;
    // console.log(iin, OcrIIN);
    // console.log(documentNumber, OcrDocNo);

    if (iin && OcrIIN && compareStrings(iin.replaceAll(/\D/g, ''), OcrIIN.replaceAll(/\D/g, ''))) {
      setIINMatch(true);
    } else {
      setIINMatch(false);
      if (iin && OcrIIN) {
        setTimeout(() => trigger('iin'), 500);
      }
    }
    if (
      documentNumber &&
      OcrDocNo &&
      compareStrings(documentNumber.replaceAll(/\D/g, ''), OcrDocNo.replaceAll(/\D/g, ''))
    ) {
      setDocNoMatch(true);
    } else {
      setDocNoMatch(false);
      if (documentNumber && OcrDocNo) {
        setTimeout(() => trigger('documentNumber'), 500);
      }
    }
  }, [formData, OcrIIN, OcrDocNo, trigger]);

  // 个人信息接口
  const { loading: loadingGet } = useRequest(getUserWebCustomerProfile, {
    manual: false,
    onSuccess(res) {
      console.log(res);
      if (res.code === 0) {
        const { mobile, iin, documentNumber, validityPeriod } = res.data;

        setPhone(mobile);
        if (validityPeriod) {
          setValidityPeriod(dayjs(validityPeriod).format('YYYY-MM-DD'));
        }
        if (iin) {
          setOcrIIN(formatDocumentNo(iin, 12));
        }
        if (documentNumber) {
          setOcrDocNo(formatDocumentNo(documentNumber, 9));
        }

        const { name } = res.data;

        fillFormFields({
          ...res.data,
          iin: formatDocumentNo(iin, 12),
          documentNumber: formatDocumentNo(documentNumber, 9),
          name: name === '-' ? '' : name, // 处理第一次完善信息，不展示设置密码时传的”-“
        });
        if (iin && documentNumber) {
          setTimeout(trigger, 500); // 回填数据后，校验数据
        }
      }
    },
  });

  // 检测邮箱是否已注册
  const [emailIsRegistered, setMailRegistered] = useState(false);
  const { run: checkEmailRegistered } = useRequest(getUserPublicEmailExist, {
    manual: true,
    onSuccess(res: { code: number; msg: string; data: boolean }) {
      if (res.code === 0) {
        if (res.data === true) {
          setMailRegistered(true);
        }
      }
    },
  });

  useEffect(() => {
    const email = storage.get('google-email-temp');
    if (email) {
      setValue?.('email', email);
    }

    return () => {
      storage.del('google-email-temp');
    };
  }, [setValue]);

  // 完善个人信息
  const [loading, setLoading] = useState(false);
  const { run: editProfile } = useRequest(postUserWebProfileEdit, {
    manual: true,
    onSuccess: async (res) => {
      if (res.code === 0) {
        await wait(1500);
        await shoppingCartSynchronizationAfterLogin?.();

        await updateEmabledStatus?.(); // 更新isEnabled状态，以及更新token
        console.log(setStepNext); // 客户暂时取消双重验证
        setStepNext();
      } else {
        message.error(res.msg, 1.5, () => {
          // setIsOpen(false);
        });
      }
      setLoading(false);
    },
    onError() {
      setLoading(false);
    },
  });

  const onSubmit = (data: any) => {
    // console.log(data);
    const { documentImage, documentNumber, iin } = data;
    const processedData = {
      ...data,
      documentImage: [
        { type: 'front', photo: documentImage[0] },
        { type: 'back', photo: documentImage[1] },
      ],
      validityPeriod: dayjs(validityPeriod).format('YYYY-MM-DD'),
      documentNumber: documentNumber.replace(/\D/g, ''),
      iin: iin.replace(/\D/g, ''),
      mobile: phone?.replace(/\D/g, ''),
    };
    // console.log(processedData);

    // return;
    editProfile(processedData);
    setLoading(true);
  };

  // ==================处理高度自适应
  const [formHeight, setFormHeight] = useState('65vh');
  const outerRef = useRef<any>(null);
  const labelRef = useRef<any>(null);
  const contentRef = useRef<any>(null);
  const updateHeight = useCallback(() => {
    if (outerRef.current && contentRef.current && labelRef.current) {
      const outerRect = outerRef.current.getBoundingClientRect();
      const labelHeight = labelRef.current.scrollHeight;
      const contentHeight = contentRef.current.scrollHeight;
      const availableHeight = window.innerHeight * 0.85; // 85vh
      // console.log(
      //   contentHeight,
      //   availableHeight,
      //   outerRect.top,
      //   labelHeight,
      //   availableHeight - outerRect.top - labelHeight,
      // );

      if (contentHeight > availableHeight - outerRect.top - labelHeight) {
        // Content is taller than available space, set max-height
        setFormHeight(`calc(85vh - ${outerRect.top + labelHeight}px)`);
      } else {
        // Content fits, remove max-height
        setFormHeight('auto');
      }
    }
  }, []);

  useEffect(() => {
    // Initial update
    updateHeight();

    // Listen for window resize
    window.addEventListener('resize', updateHeight);

    // Set up ResizeObserver to watch for content changes
    const resizeObserver = new ResizeObserver(updateHeight);
    if (contentRef.current) {
      resizeObserver.observe(contentRef.current);
    }

    return () => {
      window.removeEventListener('resize', updateHeight);
      resizeObserver.disconnect();
    };
  }, [updateHeight]);
  // =====================

  const disabled = useMemo(() => {
    if (emailIsRegistered) return true;
    // console.log(isIINMatch, isDocNoMatch, isValid, isDirty);
    const isIDPicturesComplete = images.filter((ele) => ele && ele.includes('http')).length === 2;

    return !isValid || !isDirty || !isIINMatch || !isDocNoMatch || !isIDPicturesComplete || ocrVerifyFail || uploadFail;
  }, [isIINMatch, isDocNoMatch, isValid, isDirty, images, ocrVerifyFail, uploadFail, emailIsRegistered]);

  // 检查邮箱是否存在--防抖
  const timerRef = useRef<Timeout | null>(null);
  const debouncedCheckEmail = useCallback(
    (params: any) => {
      if (timerRef.current) {
        // timerRef.current=null // bug
        clearTimeout(timerRef.current); // 必须用 clearTimeout
      }
      timerRef.current = setTimeout(() => {
        checkEmailRegistered(params);
        timerRef.current = null;
      }, 1500);
    },
    [checkEmailRegistered],
  );

  return (
    <div
      ref={outerRef}
      className="w-96 rounded-3xl bg-white pb-10 pt-6 shadow-md max-md:h-screen max-md:w-full max-md:rounded-none"
    >
      <div className="w-full md:hidden">
        <HeaderBackH5 goBack={goBack} />
      </div>

      <div ref={labelRef}>
        {loadingGet ? <NewLoading /> : ''}
        <h2 className="mb-6 text-center text-xl font-bold">{t('personalInfo')}</h2>

        <div className="px-6">
          {/* <InputField label={t('name')} name="name" value={name} disabled className="my-4" withClearIcon={false} /> */}
          <InputField
            label={t('phone')}
            name="phone"
            value={formatPhoneNumberFull(phone as string)}
            disabled
            className="my-4 opacity-50"
            withClearIcon={false}
            classNames={{ labelClass: '!text-[12px]' }}
          />
        </div>

        <div className="mb-4 flex items-center justify-center bg-blue-100 p-2 px-6">
          <TanIcon width="24" />
          <p className="pl-2 text-sm text-[#1890FF]">{`Ваша личная информация нужна для оформления доставки`}</p>
        </div>
      </div>

      <div
        ref={contentRef}
        style={{ maxHeight: formHeight }}
        className="hide-extra-scrollbar diy-scrollbar overflow-y-auto px-6 pb-6"
      >
        {/* <ScrollArea style={{ height: formHeight }} ref={contentRef} className="px-6 pb-6"> */}
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            onChange={(e) => {
              console.log(e);

              initRef.current = false;
            }}
            className="space-y-4"
          >
            <FormField
              control={form.control}
              name="name"
              rules={{
                // required: t('required'),
                // pattern: {
                //   value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                //   // message: 'please input valid email',
                //   message: t('emailpatternmsg'),
                // },
                validate: (value) => {
                  if (!value) return t('required');
                  if (value.trim()) {
                    const res = validateUserNameIntel02(value.trim())[currentLocale as 'en' | 'ru' | 'kk'];
                    if (res) return res;
                  }
                  return true;
                },
              }}
              render={({ field, fieldState }) => {
                // console.log(field, fieldState);

                return (
                  <CustomFormItem fieldState={fieldState}>
                    <div className="text-[12px]">{t('name')}</div>
                    <CustomInput
                      placeholder={NamePlaceHolers[currentLocale as 'en' | 'ru' | 'kk']}
                      // placeholder={t('emailplaceholder')}
                      {...field}
                      onClear={() => form.setValue('name', '', { shouldValidate: true })}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        field.onChange(e);
                        form.trigger('name');
                      }}
                    />
                  </CustomFormItem>
                );
              }}
            />
            <FormField
              control={form.control}
              name="email"
              rules={{
                // required: t('required'),
                // pattern: {
                //   value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                //   // message: 'please input valid email',
                //   message: t('emailpatternmsg'),
                // },
                validate: (value) => {
                  // validate只在输入时校验
                  if (!value) return t('required');
                  const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/g;
                  if (!reg.test(value)) {
                    return t('emailpatternmsg');
                  }
                  return true;
                },
              }}
              render={({ field, fieldState }) => {
                // console.log(field, fieldState);

                return (
                  <CustomFormItem fieldState={fieldState}>
                    <div className="text-[12px]">{t('email')}</div>
                    <CustomInput
                      placeholder={t('emailplaceholder')}
                      {...field}
                      onClear={() => form.setValue('email', '', { shouldValidate: true })}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        field.onChange(e);
                        form.trigger('email');
                        setMailRegistered(false);
                        if (validateEmailOrMobile(e.target.value) === 'email') {
                          //还需要做防抖
                          debouncedCheckEmail({ email: e.target.value });
                        }
                      }}
                    />
                  </CustomFormItem>
                );
              }}
            />

            {emailIsRegistered && (
              <div className="mt-1 flex items-center px-0 py-0" role="alert">
                <AlertIcon />
                <p className="ml-1 mt-0 text-xs text-[#FF3141]">{t('emailRegistered')}</p>
              </div>
            )}

            <FormField
              control={form.control}
              name="iin"
              rules={{
                validate: (value) => {
                  if (!value) return t('required');
                  if (!/^\d{3} \d{3} \d{3} \d{3}$/.test(value)) return t('Invalidformat');
                  // console.log(compareStrings(value.replaceAll(/\D/g, ''), OcrIIN.replaceAll(/\D/g, '')));

                  if (
                    formData.iin &&
                    images[0] &&
                    !compareStrings(value.replaceAll(/\D/g, ''), OcrIIN.replaceAll(/\D/g, ''))
                  )
                    return t('iinNotMatchOrc');
                  return true;
                },
              }}
              render={({ field, fieldState }) => (
                <FormItem>
                  <CustomFormItem fieldState={fieldState}>
                    <p className="text-[12px] text-black">{t('nnh')}</p>
                    <FormControl>
                      <CustomInput
                        // placeholder={t('nnhplaceholder')}
                        placeholder="990 117 000 000"
                        {...field}
                        onClear={() => form.setValue('iin', '', { shouldValidate: true })}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleFormattedChange(
                            e,
                            (e) => {
                              field.onChange(e);
                              form.trigger('iin');
                            },
                            {
                              shouldRemoveNonDigits: true,
                              formatFn: formatDocumentNo,
                              formatArgs: [12],
                            },
                          );
                        }}
                      />
                    </FormControl>
                  </CustomFormItem>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="documentNumber"
              rules={{
                validate: (value) => {
                  if (!value) return t('documentNumberRequired');
                  if (!/^\d{3} \d{3} \d{3}$/.test(value)) return t('Invalidformat');
                  if (
                    formData.documentNumber &&
                    images[1] &&
                    !compareStrings(value.replaceAll(/\D/g, ''), OcrDocNo.replaceAll(/\D/g, ''))
                  )
                    return t('documentNumberNotMatchOrc');
                  return true;
                },
              }}
              render={({ field, fieldState }) => (
                <FormItem>
                  <CustomFormItem fieldState={fieldState}>
                    <p className="text-[12px] text-black">{t('documentNumber')}</p>
                    <FormControl>
                      <CustomInput
                        placeholder="000 000 000"
                        {...field}
                        onClear={() => form.setValue('documentNumber', '', { shouldValidate: true })}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleFormattedChange(
                            e,
                            (e) => {
                              field.onChange(e);
                              form.trigger('documentNumber');
                            },
                            {
                              shouldRemoveNonDigits: true,
                              formatFn: formatDocumentNo,
                              formatArgs: [9],
                            },
                          );
                        }}
                      />
                    </FormControl>
                  </CustomFormItem>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="validityPeriod"
              disabled
              rules={{ required: t('required') }}
              render={({ field, fieldState }) => {
                console.log(field, fieldState);

                return (
                  <FormItem className="flex flex-col">
                    <CustomFormItem fieldState={fieldState}>
                      <p className="text-[12px] text-black">{t('expiredate')}</p>
                      <CustomDatePicker
                        // value={formData.validityPeriod}
                        value={validityPeriod}
                        onChange={setValidityPeriod}
                        textClassName="text-[14px]"
                        // disabled={true}
                      />
                    </CustomFormItem>
                  </FormItem>
                );
              }}
            />

            <FormField
              control={form.control}
              name="documentImage"
              rules={{ required: t('required') }}
              render={({ field, fieldState }) => (
                <FormItem>
                  <CustomFormItem fieldState={fieldState}>
                    <FormControl>
                      <CropperUpload
                        {...field}
                        form={form}
                        setOcrIIN={setOcrIIN}
                        setOcrDocNo={setOcrDocNo}
                        // images={images}
                        images={images}
                        setImages={setImages}
                        setValidityPeriod={setValidityPeriod}
                        setUploadFail={setUploadFail}
                        setOcrVerifyFail={setOcrVerifyFail}
                        classNames={{ labelClass: '!text-[12px]' }}
                      />
                    </FormControl>
                  </CustomFormItem>
                </FormItem>
              )}
            />
            {uploadFail && (
              <div className="mt-1 flex items-center px-0 py-0" role="alert">
                <AlertIcon />
                <p className="ml-1 mt-0 text-xs text-[#FF3141]">{t('uploadfail')}</p>
              </div>
            )}
            {!uploadFail && ocrVerifyFail && (
              <div className="mt-1 flex items-center px-0 py-0" role="alert">
                <AlertIcon />
                <p className="ml-1 mt-0 text-xs text-[#FF3141]">{t('ocrVerifyFail')}</p>
              </div>
            )}

            <button
              type="submit"
              disabled={disabled}
              className={`flex h-12 w-full cursor-pointer flex-row items-center justify-center rounded-xl bg-[#4DBAC0] text-base font-medium hover:opacity-85 disabled:bg-[#9BA1A8] ${disabled ? 'text-[var(--theme-gray-1)] text-white' : 'text-black'}`}
            >
              {loading ? <NewLoading type="special" /> : t('register')}
            </button>
          </form>
        </Form>
        {/* </ScrollArea> */}
      </div>
    </div>
  );
};

export default PersonalInfoForm;

export { compareStrings };
