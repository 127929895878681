'use client';

import { getProductPublicWebBannerList } from '@/lib/services/zhuye';
import { useRequest } from 'ahooks';
import Image from 'next/image';
import Link from 'next/link';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import storage from '@/shared/utils/storage';
import { SEARCH_PAGE_COVER_IMAGE } from '@/shared/constants';
// import { Drawer, DrawerContent, DrawerTrigger } from '@/components/ui/drawer';
// import { startTransition, useState } from 'react';
// import SelectLanguageTrigger from '@/components/commons/SelectLanguageTrigger';
// import { useLocale, useTranslations } from 'next-intl';
// import useIntelBaseRoute from '@/lib/hooks/useIntelBaseRoute';
// import { useRouter } from 'next/router';
// import { usePathname } from 'next/navigation';
// import { useDarkModeProvider } from '@/lib/context';

const HomePageBanner = () => {
  // const currentLocale = useLocale();
  // const t = useTranslations('Header');
  // const { query, replace } = useRouter();
  // const { setbaseRoute } = useIntelBaseRoute();
  // const pathname = usePathname();

  // const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  // Banner列表
  const { data: bannerListData } = useRequest(getProductPublicWebBannerList, {
    manual: false,
    cacheKey: 'home-page-banner',
  });
  // const { darkMode } = useDarkModeProvider();

  return (
    <section className="w-full">
      <div className="w-full">
        <Swiper
          modules={[Pagination, Autoplay]}
          spaceBetween={50}
          slidesPerView={1}
          autoplay={{
            delay: 5000,
          }}
          loop
          pagination={{ clickable: true }}
        >
          {(bannerListData?.data || []).map((bannerItem, index) => {
            return (
              <SwiperSlide key={index}>
                <Link
                  // 在新标签页打开详情页面
                  target={bannerItem?.target === 'DETAIL' && bannerItem?.productId ? '_blank' : '_self'}
                  href={
                    bannerItem?.target === 'SEARCH'
                      ? `/search?keywords=${bannerItem.searchKeywords}`
                      : bannerItem?.target === 'DETAIL'
                        ? bannerItem?.productId
                          ? '/product/' + bannerItem?.productId
                          : '/search'
                        : bannerItem?.url || '/search'
                  }
                  onClick={() => {
                    if (bannerItem?.target === 'SEARCH') {
                      storage.set(SEARCH_PAGE_COVER_IMAGE, bannerItem?.searchPageCoverImage);
                    } else {
                      storage.del(SEARCH_PAGE_COVER_IMAGE);
                    }
                  }}
                >
                  <div className="h-[16.67vw] w-full max-md:hidden 2xl:h-[13.67vw]">
                    <Image
                      src={bannerItem.image || '/images/banner/defaultImage.png'}
                      width={1440}
                      height={240}
                      className="h-full w-full object-cover"
                      alt="bannerImage"
                    />
                  </div>
                  <div className="h-[212px] w-full md:hidden">
                    <Image
                      src={bannerItem.imageForApp || '/images/banner/defaultImage.png'}
                      width={1440}
                      height={212}
                      className="h-full w-full object-cover"
                      alt="bannerImage"
                    />
                  </div>
                </Link>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      {/* <div className="mb-3 flex h-10 w-full flex-row items-center justify-center md:hidden">
        <Drawer open={openDrawer} onOpenChange={setOpenDrawer}>
          <DrawerTrigger>
            <SelectLanguageTrigger darkMode={darkMode} currentLocale={currentLocale} />
          </DrawerTrigger>
          <DrawerContent className="rounded-t-3xl">
            <div className={`w-full rounded-t-3xl pb-6 pt-3 ${darkMode ? 'bg-[#141414]' : 'bg-white'}`}>
              <div
                className={`flex h-12 w-full flex-row items-center justify-center text-base font-medium ${darkMode ? 'text-white' : 'text-black'}`}
              >
                {t('chooseLanguage')}
              </div>
              {['kk', 'ru', 'en'].map((locale) => {
                return (
                  <div
                    key={locale}
                    className={`w-ful flex h-12 cursor-pointer flex-row items-center justify-center ${currentLocale === locale ? `border-[1px] border-solid ${darkMode ? 'border-[#3A969B] bg-[#3a969b1f]' : 'border-[#4DBAC0] bg-[#4dbac01f]'} ` : darkMode ? 'bg-black' : 'bg-white'}`}
                    onClick={() => {
                      setbaseRoute('/' + locale);
                      startTransition(() => {
                        replace({ pathname, query: query }, { pathname, query: query }, { locale: locale });
                      });
                      setOpenDrawer(false);
                    }}
                  >
                    <Image
                      width={24}
                      height={24}
                      src={
                        locale === 'ru'
                          ? '/images/navBar/flag2.png'
                          : locale === 'en'
                            ? '/images/navBar/flag3.png'
                            : '/images/navBar/flag1.png'
                      }
                      className={`mr-3 ${locale === 'en' ? 'h-auto w-[23px]' : 'size-6'}`}
                      alt="image"
                    />
                    <div className={`text-base font-medium ${darkMode ? 'text-white' : 'text-black'}`}>
                      {locale === 'en' ? 'English' : locale === 'ru' ? 'Русский' : 'Қазақша'}
                    </div>
                  </div>
                );
              })}
            </div>
          </DrawerContent>
        </Drawer>
      </div> */}
    </section>
  );
};

export default HomePageBanner;
