import { NewLoading } from '@/components/commons';
import React, { useState, useEffect, useRef } from 'react';

const AutoHeightIframe = ({
  src,
  title = 'Iframe content',
  width = '100%',
  className = '',
  // onLoad,
  onError,
  sandbox = 'allow-same-origin allow-scripts',
}: any) => {
  const [height, setHeight] = useState<number>(0);
  const iframeRef = useRef<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  // const heightSetTimer = useRef<any>();

  useEffect(() => {
    const iframe = iframeRef.current;
    if (!iframe) return;

    const handleResize = () => {
      console.info('resize', iframeRef.current);
      try {
        // 获取iframe内容文档的高度
        const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
        // const newHeight = iframeDoc.documentElement.scrollHeight;
        // console.dir(iframeDoc);
        // console.info(iframeDoc, newHeight, iframeDoc.body.scrollHeight);
        // console.info(iframeDoc, newHeight, iframeDoc.children[0].scrollHeight, iframeDoc.body.scrollHeight);
        // setHeight(newHeight);
        setHeight(iframeDoc.body.scrollHeight);

        console.info('article_loading finish');
      } catch (e: any) {
        console.warn('Cannot access iframe content - possible cross-origin restriction', e);
        console.info('Cannot access iframe content - possible cross-origin restriction', e);
      }
    };

    const handleLoad = () => {
      console.info('handleLoad---');
      setIsLoading(false);

      setTimeout(() => {
        handleResize();
      }, 100);

      try {
        // 监听iframe内容变化
        const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
        console.log(iframeDoc.body);

        const resizeObserver = new ResizeObserver(() => {
          // handleResize();
          setTimeout(() => {
            handleResize();
          }, 100);
          console.info('iframe内容变化');
        });
        resizeObserver.observe(iframeDoc.body);

        return () => resizeObserver.disconnect();
      } catch (e: any) {
        console.warn('Cannot access iframe content - possible cross-origin restriction', e);
      }
    };

    iframe.addEventListener('load', handleLoad);

    return () => {
      iframe.removeEventListener('load', handleLoad);
    };
  }, [src]);

  return (
    <div className="relative h-full w-full">
      {isLoading && (
        <div className="absolute inset-0 top-20 flex items-center justify-center bg-gray-50">
          <NewLoading />
        </div>
      )}
      <iframe
        ref={iframeRef}
        src={src}
        title={title}
        width={width}
        height={height}
        className={`border-0 ${className}`}
        onError={onError}
        sandbox={sandbox}
      />
    </div>
  );
};

export default AutoHeightIframe;
