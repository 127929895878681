import React from 'react';

const SuccessIcon = ({ width = '96', height = '96' }) => (
  <svg width={width} height={height} viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="48" cy="48" r="40" fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M88 48C88 70.0914 70.0914 88 48 88C25.9086 88 8 70.0914 8 48C8 25.9086 25.9086 8 48 8C70.0914 8 88 25.9086 88 48ZM64.1213 35.8787C65.2929 37.0503 65.2929 38.9497 64.1213 40.1213L44.1213 60.1213C42.9497 61.2929 41.0503 61.2929 39.8787 60.1213L31.8787 52.1213C30.7071 50.9497 30.7071 49.0503 31.8787 47.8787C33.0503 46.7071 34.9497 46.7071 36.1213 47.8787L42 53.7574L50.9393 44.818L59.8787 35.8787C61.0503 34.7071 62.9497 34.7071 64.1213 35.8787Z"
      fill="#6DD23D"
    />
  </svg>
);

export default SuccessIcon;
