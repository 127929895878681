import React from 'react';
import HomePageBanner from '../HomePageBanner';
import PopularCategories from '../PopularCategories';
import RecentlyViewed from '../RecentlyViewed';
import RecentlyViewedForH5 from '../RecentlyViewedForH5';
import Popular from '../Popular';
import PopularForH5 from '../PopularForH5';
import Recommend from '../Recommend';
import RecommendForH5 from '../RecommendForH5';

const HomeContent = ({ inter }: { inter: any }) => {
  const [selectedGoods, setSelectedGoods] = React.useState<any>();

  return (
    <div
      className={`flex min-h-screen flex-col items-center justify-start max-md:w-full max-md:overflow-y-auto ${inter.className}`}
    >
      {/* banner */}
      <HomePageBanner />
      <div className="w-full max-md:p-3">
        {/* 热门分类 */}
        <PopularCategories />
        {/* 最近浏览商品 */}
        <div className="w-full">
          <div className="w-full max-md:hidden">
            <RecentlyViewed
              selectedGoods={selectedGoods}
              handleCollection={(value, item) => {
                setSelectedGoods({
                  ...item,
                  favorited: value,
                });
              }}
            />
          </div>
          <div className="w-full md:hidden">
            <RecentlyViewedForH5
              selectedGoods={selectedGoods}
              handleCollection={(value, item) => {
                setSelectedGoods({
                  ...item,
                  favorited: value,
                });
              }}
            />
          </div>
        </div>
        {/* 热门商品 */}
        <div className="w-full">
          <div className="w-full max-md:hidden">
            <Popular
              selectedGoods={selectedGoods}
              handleCollection={(value, item) => {
                setSelectedGoods({
                  ...item,
                  favorited: value,
                });
              }}
            />
          </div>
          <div className="w-full md:hidden">
            <PopularForH5
              selectedGoods={selectedGoods}
              handleCollection={(value, item) => {
                setSelectedGoods({
                  ...item,
                  favorited: value,
                });
              }}
            />
          </div>
        </div>
        {/* 推荐商品 */}
        <div className="w-full">
          <div className="w-full max-md:hidden">
            <Recommend
              selectedGoods={selectedGoods}
              handleCollection={(value, item) => {
                setSelectedGoods({
                  ...item,
                  favorited: value,
                });
              }}
            />
          </div>
          <div className="w-full md:hidden">
            <RecommendForH5
              selectedGoods={selectedGoods}
              handleCollection={(value, item) => {
                setSelectedGoods({
                  ...item,
                  favorited: value,
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeContent;
