import React, { useEffect, useState } from 'react';
import { useTranslations } from 'next-intl';
import VerifyCodeWrap from '../Wrap';
import { formatPhoneNumber1112, validateEmailOrMobile } from '@/components/logreg/utils';
import { formatEmail } from '@/shared/utils/tools';
import { useRequest } from 'ahooks';
import { postUserPublicWebEmailCodeVerify, postUserPublicWebSmsCodeVerify } from '@/lib/services/gonggongjiekou';

// 目前用于忘记密码
const VerifyCodeForForgetPass = ({
  emailOrMobile,
  setContentNext,
  getResult,
  goBack,
  repData,
}: {
  emailOrMobile?: string;
  setContentNext?: () => void;
  getResult?: (v: any) => void;
  [k: string]: any;
}) => {
  const t = useTranslations('ForForgetPass');

  const [codes, setResult] = useState<string[]>([]);
  const [submitError, setSubmitError] = useState<boolean>(false);

  // 验证邮件验证码(WEB)
  const { run: toVerifyByEmail } = useRequest(postUserPublicWebEmailCodeVerify, {
    manual: true,
    onSuccess(res) {
      if (res.code === 0) {
        if (res.data.ticket) {
          getResult?.(res.data.ticket);
          setContentNext?.();
        }
      } else {
        // message.error(res.msg);
        setSubmitError(true);
      }
    },
  });

  // 验证短信验证码(WEB)
  const { run: toVerifyByMobile } = useRequest(postUserPublicWebSmsCodeVerify, {
    manual: true,
    onSuccess(res) {
      if (res.code === 0) {
        if (res.data.ticket) {
          getResult?.(res.data.ticket);
          setContentNext?.();
        }
      } else {
        // message.error(res.msg);
        setSubmitError(true);
      }
    },
  });

  const [inputType, setInputType] = useState<'email' | 'mobile' | 'invalidInput'>('invalidInput');
  useEffect(() => {
    if (emailOrMobile) {
      setInputType(validateEmailOrMobile(emailOrMobile as string));
    }
  }, [emailOrMobile]);

  return (
    <div className={`mx-auto h-fit !w-[428px] rounded-3xl bg-white p-6 shadow-lg max-md:!w-[360px]`}>
      <h2 className="mb-4 text-center text-lg font-semibold">{t('varifyCodeTitle')}</h2>
      {inputType === 'mobile' && (
        <>
          <p className="mb-1 text-center text-sm text-black">
            {t('varifyReminder1') + formatPhoneNumber1112(emailOrMobile as string)}
          </p>
          <p className="mb-1 text-center text-sm text-black">{t('varifyReminder2')}</p>
          <p className="mb-4 text-center text-sm text-black">{t('varifyReminder3')}</p>
        </>
      )}
      {inputType === 'email' && (
        <>
          <p className="mb-1 text-center text-sm text-black">{t('varifyReminder4')}</p>
          <p className="mb-1 text-center text-sm text-black">{formatEmail(emailOrMobile)}</p>
        </>
      )}

      <VerifyCodeWrap
        phoneNumber={emailOrMobile as string}
        sendType={inputType}
        noNeedLogin={true}
        // handleSubmit={handleSubmit}
        handleSubmit={() => {
          if (inputType === 'email') {
            toVerifyByEmail({ email: '' + emailOrMobile, captcha: codes.join('') });
          }
          if (inputType === 'mobile') {
            toVerifyByMobile({ phone: '' + emailOrMobile, smsCode: codes.join(''), operateType: 'FORGET_PWD_VERIFY' });
          }
        }}
        goBack={goBack}
        setResult={setResult}
        butnText1={t('confirm')}
        repData={repData}
        submitError={submitError}
        setSubmitError={setSubmitError}
      />
    </div>
  );
};

export default VerifyCodeForForgetPass;
