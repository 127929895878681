'use client';

import { useTranslations } from 'next-intl';
import DownloadMobileApplications from '../../DownloadMobileApplications';
import { Dialog, DialogContent, DialogTitle } from '@/components/ui/dialog';
import { useState } from 'react';
import ArticleDetail from '../../ArticleDetail';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useAuthProvider, useCartProvider, useCollectProvider, useDarkModeProvider } from '@/lib/context';
import { useRouter } from 'next/router';
import { useRequest } from 'ahooks';
import { getUserWebCustomerProfile } from '@/lib/services/gerenzhongxin';
import { Badge } from '@/components/ui/badge';

const Footer = ({ specialFooter = false }: { specialFooter?: boolean }) => {
  const { push } = useRouter();
  const pathname = usePathname();
  const t = useTranslations('Footer');
  const [articleId, setArticleId] = useState<number>();
  const [openArticleDetail, setOpenArticleDetail] = useState<boolean>(false);
  const { darkMode } = useDarkModeProvider();
  const { isLogin } = useAuthProvider();
  const { shoppingCartCount } = useCartProvider();
  const { collectTotal } = useCollectProvider();

  // 获取登录后的用户信息，展示头像和名字
  const { data: userInfo } = useRequest(getUserWebCustomerProfile, {
    manual: !isLogin,
    refreshDeps: [isLogin],
    cacheKey: 'narbar-userInfo',
  });

  return (
    <>
      <footer
        className={`relative w-full overflow-hidden text-muted-foreground ${specialFooter ? '' : 'max-md:hidden'} ${pathname === '/about-us' ? 'bg-white' : ''}`}
      >
        <div className={`w-full ${specialFooter ? 'px-3 py-0' : 'pb-10 pt-10'}`}>
          <div
            className={`flex items-start justify-between ${specialFooter ? 'flex-col' : 'container flex-row'} ${specialFooter && pathname === '/' ? 'pb-[60px]' : ''}`}
          >
            <div className="flex flex-col items-start justify-start">
              <div className={`text-base font-semibold text-black ${specialFooter ? 'mb-3' : 'mb-6'}`}>
                {t('company')}
              </div>
              <Link
                href={'/about-us'}
                className={`mb-3 cursor-pointer select-none text-sm ${darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'}`}
              >
                {t('aboutUs')}
              </Link>
              <div
                className={`mb-3 cursor-pointer select-none text-sm ${darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'}`}
                onClick={() => {
                  setArticleId(2);
                  setOpenArticleDetail(true);
                }}
              >
                {t('contact')}
              </div>
              <div
                className={`mb-3 cursor-pointer select-none text-sm ${darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'}`}
                onClick={() => {
                  push('/blog');
                }}
              >
                {t('blog')}
              </div>
              <div
                className={`mb-3 cursor-pointer select-none text-sm ${darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'}`}
                onClick={() => {
                  setArticleId(3);
                  setOpenArticleDetail(true);
                }}
              >
                {t('hotline')}
              </div>
            </div>
            <div className={`flex flex-col items-start justify-start`}>
              <div className={`text-base font-semibold text-black ${specialFooter ? 'mb-3' : 'mb-6'}`}>
                {t('buyer')}
              </div>
              <div className={`flex items-start justify-start ${specialFooter ? 'flex-col' : 'flex-row'}`}>
                <div className="mr-6">
                  <div
                    className={`mb-3 cursor-pointer select-none text-sm ${darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'}`}
                    onClick={() => {
                      setArticleId(4);
                      setOpenArticleDetail(true);
                    }}
                  >
                    {t('howToOrder')}
                  </div>
                  <div
                    className={`mb-3 cursor-pointer select-none text-sm ${darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'}`}
                    onClick={() => {
                      setArticleId(5);
                      setOpenArticleDetail(true);
                    }}
                  >
                    {t('paymentMethod')}
                  </div>
                  <div
                    className={`mb-3 cursor-pointer select-none text-sm ${darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'}`}
                    onClick={() => {
                      setArticleId(6);
                      setOpenArticleDetail(true);
                    }}
                  >
                    {t('delivery')}
                  </div>
                  <div
                    className={`mb-3 cursor-pointer select-none text-sm ${darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'}`}
                    onClick={() => {
                      setArticleId(7);
                      setOpenArticleDetail(true);
                    }}
                  >
                    {t('returnGoods')}
                  </div>
                  {/* <div className="text-sm text-[#80878E] mb-3">{t('refund')}</div> */}
                </div>
                <div>
                  {/* <div className="text-sm text-[#80878E] mb-3">{t('salesRules')}</div> */}
                  <div
                    className={`mb-3 cursor-pointer select-none text-sm ${darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'}`}
                    onClick={() => {
                      setArticleId(8);
                      setOpenArticleDetail(true);
                    }}
                  >
                    {t('termsOfUse')}
                  </div>
                  <div
                    className={`mb-3 cursor-pointer select-none text-sm ${darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'}`}
                    onClick={() => {
                      setArticleId(9);
                      setOpenArticleDetail(true);
                    }}
                  >
                    {t('publicQuotation')}
                  </div>
                  <div
                    className={`mb-3 cursor-pointer select-none text-sm ${darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'}`}
                    onClick={() => {
                      setArticleId(10);
                      setOpenArticleDetail(true);
                    }}
                  >
                    {t('personalData')}
                  </div>
                  <div
                    className={`mb-3 cursor-pointer select-none text-sm ${darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'}`}
                    onClick={() => {
                      setArticleId(11);
                      setOpenArticleDetail(true);
                    }}
                  >
                    {t('questionsAndAnswers')}
                  </div>
                  {/* <div className="text-sm text-[#80878E] mb-3">{t('compatibilityCheck')}</div> */}
                </div>
              </div>
            </div>
            {/* <div className="flex flex-col items-start justify-start">
              <div className="mb-6 text-base font-semibold text-black">{t('socialMedia')}</div>
              <div
                className={`mb-3 cursor-pointer select-none text-sm ${darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'}`}
              >
                Facebook
              </div>
              <div
                className={`mb-3 cursor-pointer select-none text-sm ${darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'}`}
              >
                Instagram
              </div>
              <div
                className={`mb-3 cursor-pointer select-none text-sm ${darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'}`}
              >
                YouTube
              </div>
              <div
                className={`mb-3 cursor-pointer select-none text-sm ${darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'}`}
              >
                Telegram
              </div>
            </div> */}
            <div className={`relative ${specialFooter ? 'max-md:hidden' : ''}`}>
              <DownloadMobileApplications />
            </div>
          </div>
        </div>
        <div
          className={`relative flex h-[60px] flex-row items-center justify-center bg-[#4DBAC0] ${specialFooter ? 'hidden' : ''}`}
        >
          <div className="text-sm font-medium text-black">2024 © UMAY Labs — {t('copyright')}</div>
          <p className="absolute bottom-1 right-2 text-black opacity-0">2024.11.18.b</p>
        </div>
        {/* 文章详情 弹窗 */}
        {articleId ? (
          <Dialog
            open={openArticleDetail}
            onOpenChange={(value) => {
              setOpenArticleDetail(value);
              if (!value) {
                setArticleId(undefined);
              }
            }}
          >
            <DialogTitle></DialogTitle>
            <DialogContent>
              <div
                style={{ maxWidth: 'calc(100vw - 138px)' }}
                className={`box-border h-[768px] max-h-screen w-[1240px] rounded-3xl bg-white p-8 max-md:hidden max-md:h-screen max-md:min-h-full max-md:w-screen max-md:overflow-y-auto max-md:rounded-none max-md:rounded-b-none max-md:rounded-t-[20px] max-md:p-0 max-md:py-3`}
              >
                <ArticleDetail id={articleId} onAgree={() => setOpenArticleDetail(false)} />
              </div>
              <div
                className={`box-border h-[768px] max-h-screen w-[1240px] rounded-3xl bg-white p-8 max-md:h-screen max-md:min-h-full max-md:w-screen max-md:overflow-y-auto max-md:rounded-none max-md:rounded-b-none max-md:rounded-t-[20px] max-md:p-0 max-md:py-3 md:hidden`}
              >
                <ArticleDetail id={articleId} onAgree={() => setOpenArticleDetail(false)} />
              </div>
            </DialogContent>
          </Dialog>
        ) : (
          ''
        )}
      </footer>
      <footer className="fixed bottom-0 left-0 z-50 box-border flex h-[60px] w-screen flex-row items-center justify-center border-t border-[#dfdbdb] bg-white px-4 shadow-xl md:hidden">
        <Link href={'/'} className="flex w-1/5 flex-1 flex-col items-center justify-center">
          <Image
            src={`/images/footer/${pathname === '/' ? 'home_selected' : 'home'}.png`}
            className="size-6"
            alt="homeImage"
            width={24}
            height={24}
          />
          <div
            className={`text-center text-[10px] font-medium leading-4 ${pathname === '/' ? (darkMode ? 'text-[#3A969B]' : 'text-primary') : darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'}`}
          >
            {t('home')}
          </div>
        </Link>
        <Link href={'/category'} className="flex w-1/5 flex-1 flex-col items-center justify-center">
          <Image
            src={`/images/footer/${pathname === '/category' ? 'category_selected' : 'category'}.png`}
            className="size-6"
            alt="homeImage"
            width={24}
            height={24}
          />
          <div
            className={`text-center text-[10px] font-medium leading-4 ${pathname === '/category' ? (darkMode ? 'text-[#3A969B]' : 'text-primary') : darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'}`}
          >
            {t('category')}
          </div>
        </Link>
        <Link href={'/favorites'} className="flex w-1/5 flex-1 flex-col items-center justify-center">
          <div className="relative">
            <Image
              src={`/images/footer/${pathname === '/favorites' ? 'like_selected' : 'like'}.png`}
              className="size-6"
              alt="homeImage"
              width={24}
              height={24}
            />
            {collectTotal && isLogin ? (
              collectTotal >= 10 ? (
                <Badge
                  className={`absolute right-[-8px] top-[-6px] z-10 px-[3px] text-[10px] leading-3`}
                  variant="destructive"
                >
                  {collectTotal || 0}
                </Badge>
              ) : (
                <Badge
                  className={`absolute right-[-8px] top-[-6px] z-10 px-[5px] text-[10px] leading-3`}
                  variant="destructive"
                >
                  {collectTotal || 0}
                </Badge>
              )
            ) : (
              ''
            )}
          </div>
          <div
            className={`text-center text-[10px] font-medium leading-4 ${pathname === '/favorites' ? (darkMode ? 'text-[#3A969B]' : 'text-primary') : darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'}`}
          >
            {t('like')}
          </div>
        </Link>
        <Link href={'/cart'} className="flex w-1/5 flex-1 flex-col items-center justify-center">
          <div className="relative">
            <Image
              src={`/images/footer/${pathname === '/cart' ? 'cart_selected' : 'cart'}.png`}
              className="size-6"
              alt="homeImage"
              width={24}
              height={24}
            />
            {shoppingCartCount ? (
              <Badge
                className={`absolute right-[-8px] top-[-6px] z-10 ${shoppingCartCount > 10 ? 'px-[2px]' : 'px-[5px]'} text-[10px] leading-3`}
                variant="destructive"
              >
                {shoppingCartCount}
              </Badge>
            ) : (
              ''
            )}
          </div>
          <div
            className={`text-center text-[10px] font-medium leading-4 ${pathname === '/cart' ? (darkMode ? 'text-[#3A969B]' : 'text-primary') : darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'}`}
          >
            {t('cart')}
          </div>
        </Link>
        <Link href="/user_center/h5" className="flex w-1/5 flex-col items-center justify-center">
          <Image
            width={48}
            height={48}
            src={
              (isLogin && userInfo?.data?.avatar) ||
              `/images/footer/${pathname === '/user_center/h5' ? 'users_selected' : 'users'}.png`
            }
            className="ml-2 mr-2 size-6 cursor-pointer rounded-full object-cover"
            alt="usersImage"
          />
          <div
            className={`text-center text-[10px] font-medium leading-4 ${pathname === '/user_center/h5' ? (darkMode ? 'text-[#3A969B]' : 'text-primary') : darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'}`}
          >
            {/* {userInfo?.data?.name || t('user')} */}
            {t('user')}
          </div>
        </Link>
      </footer>
    </>
  );
};

export default Footer;
